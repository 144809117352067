import { createI18n } from "vue-i18n";
const savedLanguage = localStorage.getItem("selectedLanguage");
const defaultLanguage = savedLanguage ? JSON.parse(savedLanguage).value : "ar";
const messages = {
  en: {
    message: {
      noAvailableRooms: "No available rooms",
      dragDrop: "Drag and drop the file here",
      fileName: "File Name",
      fileSize: "File Size",
      internalServerError: "Internal Server Error",
      login_error: "Sorry, the username or password is wrong!",
      invalid_email: "Invalid email",
      required_error_message: "This field is required",
      GuestUser: "Guest User",
      fullName: "Full Name",
      Whatsapp: "Contact Us on Whatsapp",
      SignIn: "Sign In",
      SignUp: "Sign Up",
      SelectCurrency: "Select Your Currency",
      SelectedCurrency: "Selected Currency",
      AllCurrencies: "All Currencies",
      SelectLanguage: "Select Your Language",
      SelectedLanguage: "Selected Language",
      AllLanguages: "All Languages",
      AccountSettings: "Account Settings",
      Bookings: "Bookings",
      Favorites: "Favorites",
      Logout: "Logout",
      home: "Home",
      hotels: "Hotel List",
      offers: "Offers and Rewards",
      faq: "FAQ",
      contact: "Contact Us",
      accountSettings: "Account Settings",
      bookings: "Bookings",
      favorites: "Favorites",
      logout: "Logout",
      bookingIraq: "Bookingiraq",
      city: "city",
      sliderText: "Book your favorite hotel in your favorite city with ease",
      sliderTextBottom: "Find the best offers for hotels and rooms",
      whereToGo: "Where do you want to go?",
      durationOfStay: "How long will you stay?",
      numberOfPeople: "Number of people?",
      numberOfRooms: "Number of rooms",
      specifyDate: "specify the date of stay (from - to)",
      numberOfAdults: "Number of adults",
      numberOfChildren: "Number of children",
      childAge: "Child age {index}",
      year: "Year",
      room: "Room",
      adult: "Adult",
      child: "Child",
      header: "Our Current Offers",
      description: "Enjoy the best offers throughout the year",
      endsAt: "This offer ends at",
      showMore: "Show More",
      exploreIraq: "Explore Iraq",
      popularDestinations: "These popular destinations have a lot to offer",
      placesToStay: "places to stay in",
      discountsHeader: "Your Discounts",
      discountsDescription:
        "Enjoy stays at the best places with the best prices",
      discountPercentage: "Discount percentage",
      recommendedHotelsHeader: "Recommended Hotels",
      recommendedHotelsDescription:
        "To enjoy the best experience, here is a list of the best hotels",
      nearbyAccommodationsHeader: "Nearby Accommodations",
      nearbyAccommodationsDescription:
        "Explore accommodations near your destination",
      placesToStayIn: "places to stay",
      footerDescription:
        "The largest hotel booking platform in Iraq. With BookingIraq, you can easily book hotels from your favorite Iraqi cities. All you need to do is choose your destination, number of people, and duration of stay, and we will do the rest.",
      contactUsText: "You can contact us via the following methods:",
      mainLinks: "Main Links",
      otherLinks: "Other Links",
      termsAndConditions: "Terms and Conditions",
      privacyPolicy: "Privacy Policy",
      footerText:
        "All rights reserved to Booking Iraq for Travel and Tourism LLC - 2024",
      sliderTextBottomHotel:
        "Search through more than 2,562 accommodations in Iraq",
      hotelList: "Hotel List",
      viewMode: "View Mode",
      sortBy: "Sort by: Best picks for you",
      itemsPerPage: "Items per page: 12",
      mainFilters: "Main Filters",
      filterByPrice: "Filter by Price",
      stars: "Accommodation Stars",
      amenities: "Accommodation Features",
      noHotels: "No Hotels",
      noFaq: "No FAQ In this section",
      tryDifferentSearch: "Try searching for different results",
      hotelsCount: "hotels",
      notFound: "No hotels found",
      searchDifferentResults: "Try searching for different results",
      starRating: "Star Rating",
      noDescription:
        "No description available. Description will be added soon.",
      readMore: "Read more",
      chooseYourRoom: "Choose Your Room",
      selectBookingRooms: "Select Booking Rooms",
      bookAndViewRoom: "Book and View Room",
      bookNow: "Book Now",
      bookNowDescription:
        "Choose arrival and departure dates and booking details to complete booking",
      roomDetails: "Room Details",
      services: "Services",
      bedroom: "Bedroom",
      accommodates: "Accommodates",
      persons: "Persons",
      downloadVoucher: "Download Voucher",
      includingTaxes: "Including Taxes and Fees",
      validReservations: "Valid Reservations",
      previousReservations: "Previous Reservations",
      IncompleteReservations: "Incomplete Reservations",
      viewLocationOnMap: "View Location on Map",
      bookingSuccess: "Booking Confirmed",
      bookingDetails: "Booking Details",
      yourBooking: "Your Booking",
      arrivalDate: "Arrival Date",
      departureDate: "Departure Date",
      totalStayDuration: "Total Stay Duration",
      youSelected: "You Selected",
      roomFor: "Room for",
      totalCostSummary: "Total Cost Summary",
      originalPrice: "Original Price",
      discount: "Discount",
      priceAfterDiscount: "Price After Discount",
      enterDiscountCode: "Enter Discount Code if you have any",
      finalPrice: "Final Price including taxes and fees",
      firstName: "First Name",
      lastName: "Last Name",
      emailAddress: "Email Address",
      emailHint: "(Booking confirmation will be sent to this address)",
      address: "Address",
      country: "Country",
      phone: "Phone",
      phoneHint: "(Preferably mobile phone)",
      totalFinalCost: "Total Final Cost",
      completeBooking: "Complete Booking",
      bookingSummary: "Booking Summary",
      enterYourDetails: "Enter Your Details",
      enterBookingDetailsWarning:
        "Please enter your information for payment. Please note that once payment is completed, you cannot refund the amount",
      bookingSuccessful: "Booking Successful",
      thankYou: "Thank you for using BookingIraq",
      welcomeToHelpCenter: "Welcome to the Help Center",
      currentOffers: "Our Current Offers",
      contactUs: "Contact Us",
      myBookings: "My Bookings",
      helpCenterDescription:
        "We are available 24/7 to serve you and answer all your inquiries.",
      offersDescription: "Enjoy the best offers all year round.",
      contactUsDescription:
        "Don't hesitate to contact us. We need your feedback to improve our services.",
      myBookingsDescription: "Track your current and future bookings.",
      faqTitle: "Didn't find the answer to your questions?",
      faqDescription:
        "You can always contact us, we are always here to serve you and answer your inquiries.",
      goToCustomerService: "Go to Customer Service Section",
      contactUsViaWhatsApp: "Contact us directly via WhatsApp",
      faqHeader: "Frequently Asked Questions",
      faqParagraph: "You may easily find what you're looking for in the FAQs",
      bookingMethods: "Booking Methods",
      customerService: "Customer Service",
      touristPlaces: "Tourist Places",
      contactHeader: "Stay Connected Through the Following Addresses",
      email: "Email",
      locationDescription: "Description of Property",
      location: "Location",
      office: "OFFICE 203 / 7",
      locationDetails: "UAE DUBAI - AL GARHOUD SR-10 OFFICE",
      sendMessage: "Send Message",
      message: "Message",
      contactMethods: "You can contact us through the following methods",
      filterByProvince: "Filter by Province",
      sort: "Sort",
      offerEnds: "Offer ends in",
      touristAttractions: "Tourist Attractions",
      apply: "apply",
      discountCode: "Discount Code",
      rate: "Rate (min)",
      starsAcc: "Stars (min)",
      total_views: "Total Views",
      long: "Longitude",
      lat: "Latitude",
      start_price: "Start Price",
      end_price: "End Price",
      is_active: "Active",
      smoking_policy: "Smoking Policy",
      has_pool: "Has Pool",
      has_gym: "Has Gym",
      has_restaurant: "Has Restaurant",
      has_bar: "Has Bar",
      has_brakfast: "Includes Breakfast",
      reserve_without_credit: "Reserve Without Credit",
      has_sauna: "Has Sauna",
      has_room_service: "Has Room Service",
      has_sweet_rooms: "Has Suites",
      has_free_parking: "Has Free Parking",
      show_in_website: "Show on Website",
      rateHotel: "Rate the Hotel",
      invalidToken: "Invalid Token",
      alreadyRated: "You already submitted your rating to this hotel.",
      submit: "Submit",
      ratingSubmitted: "Rating submitted successfully!",
      yourOpinion: "Write your Opinion",
      guestRatings: "Guest Ratings",
      exceptional: "Exceptional",
      reviews: "reviews",
      viewAllReviews: "View all reviews",
      ratings: "Ratings",
      last20Reviews: "Last 20 reviews",
      ratedOn: "Rated on",
      noOffers: "No Offers",
      favPlaces: "My Favorite Places",
      favDescription: "Liked a place? Add it to favorites and book it later.",
      noFavPlaces: "No favorite places",
      addFavFromMain: "You can add favorite places from the main hotel list.",
      locationUnavailable: "Location is Unavailable",
      noAvailableServices: "No Available Services",
      days: "Days",
      hour: "Hour",
      minutes: "Minute",
      fullname: "Full name",
      availableServices: "Available Services",
    },
    actions: {
      bookAndViewRoom: "View Room",
      bookNow: "Book Now",
      done: "Done",
      deselect: "deselect",
      search: "Search",
      select_room: "Select",
      deselect_room: "Deselect",
    },
  },
  ar: {
    message: {
      noAvailableRooms: "لا توجد غرف متاحة",
      dragDrop: "اسحب واسقط الملف هنا",
      fileName: "اسم الملف",
      fileSize: "حجم الملف",
      internalServerError: "خطأ داخلي في الخادم",
      login_error: "عذراً، اسم المستخدم او كلمة المرور خطأ",
      required_error_message: "هذا الحقل مطلوب",
      invalid_email: "يرجى كتابة بريد الكتروني صحيح",
      GuestUser: "مستخدم ضيف",
      Whatsapp: "تواصل معنا عبر الواتساب",
      SignIn: "تسجيل دخول ",
      SignUp: "انشاء حساب",
      SelectCurrency: "اختر عملتك",
      SelectedCurrency: "العملة المختارة",
      AllCurrencies: "جميع العملات",
      SelectLanguage: "اختر لغتك",
      SelectedLanguage: "اللغة المختارة",
      AllLanguages: "جميع اللغات",
      AccountSettings: "اعدادات الحساب",
      Bookings: "الحجوزات",
      Favorites: "المفضلة",
      Logout: "تسجيل خروج",
      home: "الرئيسية",
      hotels: "قائمة الفنادق",
      offers: "الجوائز والعروض",
      faq: "الاسئلة الشائعة",
      contact: "تواصل معنا",
      accountSettings: "اعدادات الحساب",
      bookings: "الحجوزات",
      favorites: "المفضلة",
      logout: "تسجيل خروج",
      bookingIraq: "Bookingiraq",
      sliderText: "احجز فندقك المفضل في مدينتك المفضلة بكل سهولة",
      sliderTextBottom: "ابحث على افضل العروض الخاصة بالفنادق والغرف",
      whereToGo: "اين تود ان تذهب؟",
      durationOfStay: "كم هي مدة الاقامة؟",
      numberOfPeople: "كم هو عدد الاشخاص؟",
      numberOfRooms: "عدد الغرف",
      specifyDate: "تحديد تاريخ الاقامة (من - الى)",
      numberOfAdults: "عدد البالغين",
      numberOfChildren: "عدد الاطفال",
      childAge: "عمر الطفل {index}",
      year: "سنة",
      room: "غرفة",
      adult: "بالغ",
      child: "طفل",
      header: "عروضنا الحالية",
      description: "تمتع بأقوى العروض على مدار السنة",
      endsAt: "ينتهي هذا العرض عند",
      showMore: "عرض المزيد",
      exploreIraq: "استكشف العراق",
      popularDestinations: "لدى هذه الوجهات الرائجة الكثير لتقدّمه",
      placesToStay: "مكان اقامة في",
      discountsHeader: "الخصومات الخاصة بك",
      discountsDescription: "تمتع بالاقامة في ارقى الاماكن بانسب الاسعار",
      discountPercentage: "نسبة الخصم",
      recommendedHotelsHeader: "الفنادق الموصى بها",
      recommendedHotelsDescription:
        "للتمتع بأفضل تجربة اليك قائمة بأفضل الفنادق",
      nearbyAccommodationsHeader: "اماكن الاقامة القريبة من وجهتك",
      nearbyAccommodationsDescription: "استكشف اماكن الاقامة القريبة من وجهتك",
      placesToStayIn: "مكان اقامة",
      footerDescription:
        "اكبر منصة لحجز الفنادق في العراق. من خلال BookingIraq يمكنك حجز الفنادق من المدن العراقية المفضلة لديك بكل سهولة واريحية. كل ما عليك فعله هو تحديد الوجهة الخاصة بك, عدد الاشخاص ومدة الاقامة ونحن نقوم بالباقي.",
      contactUsText: "يمكنك التواصل معنا عبر الطرق الاتية:",
      mainLinks: "الروابط الرئيسية",
      otherLinks: "روابط اخرى",
      termsAndConditions: "الشروط والاحكام",
      privacyPolicy: "سياسة الخصوصية",
      footerText:
        "جميع الحقوق محفوظة لصالح شركة بوكنك عراق للسفر والسياحة ذ.م.م - 2024",
      sliderTextBottomHotel: "ابحث من خلال اكثر من 2,562 مكان اقامة في العراق",
      hotelList: "قائمة الفنادق",
      viewMode: "طريقة العرض",
      sortBy: "ترتيب حسب: افضل ما اخترناه لك",
      itemsPerPage: "عدد العناصر الظاهر : 12",
      mainFilters: "الفلاتر الاساسية",
      filterByPrice: "فلتر حسب السعر",
      stars: "عدد النجوم لمكان الاقامة",
      starsAcc: "عدد النجوم",
      amenities: "مميزات مكان الاقامة",
      noHotels: "لا يوجد فنادق",
      tryDifferentSearch: "حاول البحث على نتائج بحث مختلفة",
      hotelsCount: "مكان اقامة",
      notFound: "لا يوجد فنادق",
      searchDifferentResults: "حاول البحث على نتائج بحث مختلفة",
      starRating: "عدد النجوم",
      noDescription: "لا يوجد وصف، سيتم اضافة الوصف قريبا ..",
      readMore: "اقرأ المزيد",
      chooseYourRoom: "أختر غرفتك",
      selectBookingRooms: "حدد غرف الحجز",
      bookAndViewRoom: "عرض الغرفة",
      bookNow: "احجز الان",
      bookNowDescription:
        "اختر تاريخ الوصول والمغادرة وتفاصيل الحجز من اجل اكمال الحجز",
      city: "المدينة",
      roomDetails: "تفاصيل الغرفة",
      services: "الخدمات",
      bedroom: "غرفة نوم",
      accommodates: "يكفي",
      persons: "شخص",
      validReservations: " الحجوزات السارية",
      includingTaxes: "شامل الضرائب والرسوم",
      downloadVoucher: "تحميل وصل الحجز",
      IncompleteReservations: "الحجوزات الغير مكتملة",
      previousReservations: "الحجوزات السابقة",
      viewLocationOnMap: "عرض المكان على الخريطة",
      bookingSuccess: "تم الحجز",
      thankYou: "شكرا لاستخدامك BookingIraq",
      bookingDetails: "تفاصيل الحجز",
      bedrooms: "غرفة نوم",
      sleeps: "يكفي",
      inclusiveTaxesFees: "شامل الضرائب والرسوم",
      checkInDate: "تاريخ الوصول",
      checkOutDate: "تاريخ المغادرة",
      notAvailable: "لا يوجد",
      totalStayDuration: "مدة الإقامة الإجمالية:",
      notSpecified: "لم تحدد",
      youHaveSelected: "لقد اخترت",
      roomFor: "غرفة لـ",
      adults: "اشخاص بالغين",
      priceSummaryRoom: "ملخص السعر الخاص بك للغرفة",
      originalPrice: "السعر الأصلي",
      yourBooking: "حجزك",
      arrivalDate: "تاريخ الوصول",
      departureDate: "تاريخ المغادرة",
      youSelected: "لقد اخترت",
      totalCostSummary: "ملخص التكلفة النهائية",
      discount: "نسبة الخصم",
      priceAfterDiscount: "السعر بعد التخفيض",
      enterDiscountCode: "اكتب رمز التخفيض هنا اذا كان لديك",
      finalPrice: "السعر النهائي شامل الضرائب والرسوم",
      firstName: "الاسم الاول",
      lastName: "اسم العائلة",
      emailAddress: "عنوان البريد الالكتروني",
      emailHint: "(يتم ارسال رسالة تأكيد الحجز الى هذا العنوان)",
      address: "العنوان",
      country: "الدولة",
      phone: "الهاتف",
      phoneHint: "(يفضل ان يكون هاتف محمول)",
      totalFinalCost: "مجموع التكلفة النهائية",
      completeBooking: "اكمال الحجز",
      bookingSummary: "تفاصيل الحجز",
      enterYourDetails: "أدخـل بياناتك",
      enterBookingDetailsWarning:
        "يرجى ادخال المعلومات الخاصة بك من اجل عملية الدفع. يرجى الانتباه عند اكمال الدفع لا يمكنك استرجاع المبلغ المطلوب",
      bookingSuccessful: "تم الحجز",
      welcomeToHelpCenter: "أهلاً بك في مركز المساعدة",
      currentOffers: "عروضنا الحالية",
      contactUs: "تواصل معنا",
      myBookings: "الحجوزات",
      helpCenterDescription:
        "متواجدون على مدار الساعة لخدمتكم والاجابة على جميع استفساراتكم.",
      offersDescription: "تمتع باقوى العروض على مدار السنة.",
      contactUsDescription:
        "لا تتردد في التواصل معنا فنحن في امس الحاجة لملاحظاتك لتحسين خدماتنا.",
      myBookingsDescription: "تابع حجوزاتك الحالية والمستقبلية.",
      faqTitle: "لم تجد اجابة على اسالتك؟",
      faqDescription:
        "يمكنك دائما التواصل معنا فنحن متواجدون دائما لخدمتكم والاجابة على استفساراتكم.",
      goToCustomerService: "الانتقال الى قسم خدمة العملاء",
      contactUsViaWhatsApp: "تواصل بشكل مباشر عبر الواتساب",
      faqHeader: "الاسئلة الشائعة",
      faqParagraph: "قد تجد ما تبحث عنه بسهولة في الاسئلة الشائعة",
      bookingMethods: "طرق الحجز",
      customerService: "خدمة العملاء",
      touristPlaces: "الاماكن السياحية",
      contactHeader: "كن على تواصل من خلال العناوين الاتية",
      email: "البريد الالكتروني",
      locationDescription: "Description of Property",
      location: "Location",
      office: "OFFICE 203 / 7",
      locationDetails: "UAE DUBAI - AL GARHOUD SR-10 OFFICE",
      message: "الرسالة",
      sendMessage: "ارسال الرسالة",
      fullName: "الاسم الثلاثي",
      contactMethods: "يمكنك التواصل معنا عبر الطرق الاتية",
      filterByProvince: "فرز حسب المحافظة",
      sort: "فرز",
      offerEnds: "ينتهي العرض في",
      touristAttractions: "الاماكن السياحية",
      apply: "تطبيق",
      discountCode: "رمز التخفيض",
      rate: "التقييم",
      total_views: "إجمالي المشاهدات",
      long: "خط الطول",
      lat: "خط العرض",
      start_price: "السعر الابتدائي",
      end_price: "السعر النهائي",
      is_active: "نشط",
      smoking_policy: "سياسة التدخين",
      has_pool: "مسبح",
      has_gym: "صالة رياضية",
      has_restaurant: "مطعم",
      has_bar: "بار",
      has_brakfast: "يشمل الإفطار",
      reserve_without_credit: "الحجز بدون بطاقة ائتمان",
      has_sauna: "ساونا",
      has_room_service: "خدمة الغرف",
      has_sweet_rooms: "أجنحة",
      has_free_parking: "موقف سيارات مجاني",
      show_in_website: "عرض على الموقع",
      noFaq: "لا يوجد اسئلة شائعة في هذا القسم",
      rateHotel: "قيم الفندق",
      invalidToken: "رمز غير صالح",
      alreadyRated: "لقد قمت بتقديم تقييمك لهذا الفندق بالفعل.",
      submit: "إرسال",
      ratingSubmitted: "تم إرسال التقييم بنجاح!",
      yourOpinion: "اكتب رائيك",
      guestRatings: "تقييمات الضيوف",
      exceptional: "استثنائي",
      reviews: "تقييمات",
      viewAllReviews: "عرض جميع التقييمات؟",
      ratings: "التقييمات",
      last20Reviews: "اخر 20 تقييم",
      ratedOn: "تم التقييم",
      noOffers: "لا يوجد عروض",
      favPlaces: "اماكني المفضلة",
      favDescription: "اعجبك مكان ما؟ ضعه في المفضلة واحجزه في وقت اخر",
      noFavPlaces: "لا توجد اماكن مفضلة",
      addFavFromMain: "يمكنك اضافة الاماكن المفضلة من قائمة الفنادق الرئيسية",
      locationUnavailable: "الموقع غير متوفر",
      noAvailableServices: "لا توجد خدمات متوفرة",
      days: "ايام",
      hour: "ساعة",
      minutes: "دقيقة",
      fullname: "الاسم الكامل",
      availableServices: "المرافق المتاحة",
    },
    actions: {
      bookAndViewRoom: "عرض",
      bookNow: "احجز الان",
      done: "تم",
      deselect: "الغاء",
      search: "بحث",
      select_room: "تحديد",
      deselect_room: "الغاء التحديد",
    },
  },
  kr: {
    message: {
      noAvailableRooms: "نەماوەیەکی بەرز نیە",
      dragDrop: "فایلەکە بێنە و هەناوە",
      fileName: "ناوی فایل",
      fileSize: "قەبارەی فایل",
      internalServerError: "هەڵەی سەرورەی ناوخۆ",
      login_error: "ببوورە، ناوی بەکارهێنەر یان نهێنوشەکە هەڵەیە",
      GuestUser: "میوانی",
      Whatsapp: "بە پەیوەندین لەسەر واتساپ",
      SignIn: "چوونە ژوورەوە",
      SignUp: "تۆماربوون",
      SelectCurrency: "پارەی خۆت هەڵبژێرە",
      SelectedCurrency: "پارەی هەڵبژێردراو",
      AllCurrencies: "هەموو پارەکان",
      SelectLanguage: "زمانەکەت هەڵبژێرە",
      SelectedLanguage: "زمانی هەڵبژێردراو",
      AllLanguages: "هەموو زمانەکان",
      AccountSettings: "ڕوونکردنەوەی هەساب",
      Bookings: "پەیوەندیدانەکان",
      Favorites: "پەیوەندیدانی تایبەتی",
      Logout: "دەرچوون",
      home: "سەرەتا",
      hotels: "لیستی هوتەلان",
      offers: "پیشکەشەکان و پاداشەکان",
      faq: "پرسەوەڵەکان",
      contact: "پەیوەندین",
      accountSettings: "ڕوونکردنەوەی هەساب",
      bookings: "پەیوەندیدانەکان",
      favorites: "پەیوەندیدانی تایبەتی",
      logout: "دەرچوون",
      bookingIraq: "Bookingiraq",
      city: "شار",
      sliderText: "بە ئاسانیدا هوتەلی خۆت هەڵبژێرە لە شاری خۆت",
      sliderTextBottom: "بەترین پیشکەشەکان بۆ هوتەل و کەمەرەکان دەدۆزێنەوە",
      whereToGo: "کەجا دەبەی گەڕانەوە؟",
      durationOfStay: "چند ڕوژ لە ماوەی مەقامەکەیە؟",
      numberOfPeople: "ژمارەی کەسان؟",
      numberOfRooms: "ژمارەی ژوورەکان",
      specifyDate: "دەبەی ڕێکەوتی ماندن (لە - بۆ)",
      numberOfAdults: "ژمارەی گەنجەکان",
      numberOfChildren: "ژمارەی منداڵان",
      childAge: "عمرى منداڵ {index}",
      year: "ساڵ",
      room: "ژوور",
      adult: "گەنج",
      child: "منداڵ",
      header: "پیشکەشەکانە تایبەتی ئیشی ئیشەکان",
      description: "لە ڕوژانی ساڵی تەنها ئەمەیە بەرەوپێشەکانە تایبەتی",
      endsAt: "ئەم پەیشکەشە کاتە دا ئەندامە",
      showMore: "زۆرتر پیشاندەربە",
      exploreIraq: "پشکنینی عیراق",
      popularDestinations: "ئەم شوێنە هەڵبژاردنەکان ئەزموونی زۆرەتی هەیە",
      placesToStay: "شوێنەکان بۆ ماندن لە",
      discountsHeader: "کەسەکانی بەهێزەکانت",
      discountsDescription: "بە مەواڵەکانە زۆر بە کەمترین نرخەکان بەخەرە",
      discountPercentage: "فەیستی نووسینەکان",
      recommendedHotelsHeader: "هوتەلانە پێشنیارکراوەکان",
      recommendedHotelsDescription:
        "بۆ باشترین ئەزموون، ئەم لیستەی هەڵبژاردەکان",
      nearbyAccommodationsHeader: "شوێنەکانە نزیكەکان",
      nearbyAccommodationsDescription:
        "پشکنینی شوێنەکانە نزیكەکان لە شوێنەکانت",
      placesToStayIn: "شوێنەکان بۆ ماندن",
      footerDescription:
        "بەرزترین پلاتفۆرمەکان بۆ تایبەتی فەرمی پەرلەمان لە عیراق. بە BookingIraq، دەتوانیت بە ئاسانیدا هوتەلان لە شاری تایبەتی عیراق بزانیت. تەنها دەبەی گەڕاندن، ژمارەی کەسان، و مەواڵەی ماندن، و ئیش بە جێدەهینەوە.",
      contactUsText: "تۆ دەتوانیت بە ئەم شێوەیە پەیوەندیت پێبکەیت:",
      mainLinks: "پیوندە سەرەکیەکان",
      otherLinks: "پیوندە ترەکان",
      termsAndConditions: "شەرطەکان و بەرزەکان",
      privacyPolicy: "پالیسی تایبەتی",
      footerText: "همه حقوق محفوظة لشركة بوكينج العراق للسفر والسياحة - 2024",
      sliderTextBottomHotel: "بە لە زیادە لە 2,562 شوێنەکان بەرەوپێشەی عیراق",
      hotelList: "لیستی هوتەلان",
      viewMode: "وضعی پەیوەندیدان",
      sortBy: "دەبەی تایبەتی: باشترین بەرەوپێشەکان",
      itemsPerPage: "بەرزەکان لە پەڕەیەکە: 12",
      mainFilters: "فلاترە سەرەکیەکان",
      filterByPrice: "فلتەر بەپەڕە",
      stars: "ستارەکان بۆ پێشکەش",
      amenities: "مرافق پێشکەش",
      noHotels: "هیچ هوتەلی نییە",
      noFaq: "هیچ پرسیاری شایعەکان لە ئیشکەریە کەناش لە ئیشە",
      tryDifferentSearch:
        "بە شێوەیەکی جیاواز سەردانی پەیوەندیدانەکان بەرەوپێشە",
      hotelsCount: "هوتەلان",
      notFound: "هیچ هوتەلی نەدۆزرابوو",
      searchDifferentResults:
        "بە شێوەیەکی جیاواز سەردانی پەیوەندیدانەکان بەرەوپێشە",
      starRating: "نرخی ستارەکان",
      noDescription: "تێبینی نییە. تێبینی دەزیادەکان بە داهاتووی نزیكە",
      readMore: "زیادەتر بخوێنەوە",
      chooseYourRoom: "ژوورەکەت هەڵبژێرە",
      selectBookingRooms: "هەڵبژێرە ژوورەکانەکانت",
      bookAndViewRoom: "بە شێوەیەکی تایبەتی ژوورەکانەکانت بەرەوپێشە",
      bookNow: "ئیش بکە",
      bookNowDescription:
        "دەبەی ڕێکەوتی چوونە ژوور و دەرچوونەکان و دەتوانی بەرزی پەیوەندیدان بکەیت",
      roomDetails: "پەیوەندیدانی ژوور",
      services: "خزمەتیەکان",
      bedroom: "ژوورەکانی پەیوەندیدانی ژوور",
      accommodates: "پەیوەندیدانی بەرەوپێشەکان",
      persons: "کەسان",
      downloadVoucher: "داگرتنی ووشەی پەیوەندیدان",
      includingTaxes: "پێوەندی تایبەتی یەکەکان و تۆماریان",
      IncompleteReservations: "پێوەندیدانی نەتوانراو",
      validReservations: "پێوەندیدانی سەرکەوتوو",
      previousReservations: "پێوەندیدانی پێشوو",
      viewLocationOnMap: "پەیوەندیدانی شوێن لە خەریطە",
      bookingSuccess: "پەیوەندیدانی هەڵبژێردراو",
      bookingDetails: "پەیوەندیدانی زانیاریەکان",
      yourBooking: "پەیوەندیدانی تۆ",
      arrivalDate: "بەروارەی ڕووداو",
      departureDate: "بەروارەی بەرچوون",
      totalStayDuration: "ماوەی پەیوەندیدانەکان بەرەوپێشە",
      youSelected: "تۆ هەڵبژێردراو",
      roomFor: "ژوور بۆ",
      totalCostSummary: "کورتەی بەرزەکان بەرەوپێشە",
      originalPrice: "نرخەی ڕووتنەوە",
      discount: "پێداویستی",
      priceAfterDiscount: "نرخەی کەرەوەی پێداویستی",
      enterDiscountCode: "رمزەی پێداویستی تێبەزەرە ئەگەر هەبێت",
      finalPrice: "نرخەی کۆی ئیشی تایبەتی بە یەکەکان و تۆمارەکان",
      firstName: "ناوی یەکە",
      lastName: "ناوی ئیش",
      emailAddress: "ئیمەیڵی پەیوەندیدان",
      emailHint: "(پەیوەندیدانی هەڵبژێردراو بەرەوپێشە بە ئەم ئیمەیڵە)",
      address: "ناونیشانی پەیوەندیدان",
      country: "بەڵگەی وڵات",
      phone: "تەلەفۆن",
      phoneHint: "(تەلەفۆنە موبایلی باشترە)",
      totalFinalCost: "کۆی ئیشی تایبەتی",
      completeBooking: "پایەندی هەڵبژێردراو",
      bookingSummary: "کورتەی پەیوەندیدانەکان",
      enterYourDetails: "زانیاریەکانت بەرەوپێشە",
      enterBookingDetailsWarning:
        "تکایە زانیاریەکانی خۆت بنووسە بۆ پارەدان. تکایە دڵنیابە لە کاتی پارەدان کە پارەی پەیوەندیدانت ناتوانیت بگەڕێیت",
      bookingSuccessful: "پەیوەندیدانی هەڵبژێردراو بە سەرکەوتوویی",
      thankYou: "سوپاس بۆ بەکاربردنی BookingIraq",
      welcomeToHelpCenter: "بەخێر بێیت بۆ ناوچەی یارمەتی",
      currentOffers: "پیشکەشەکانە تایبەتی",
      contactUs: "پەیوەندین",
      myBookings: "پەیوەندیدانەکانم",
      helpCenterDescription:
        "ماوەی ڕوژانی تایبەتی لە بەرەوپێشەکان و ئیشەکان بەرەوپێشە.",
      offersDescription: "پیشکەشەکان لە ڕوژانی ساڵی تەنها.",
      contactUsDescription:
        "بە پەیوەندیدان لە پەیوەندین. لەبەرەوپێشەکان بەرەوپێشە.",
      myBookingsDescription: "پەیوەندیدانەکانە تایبەتی و داهاتوو",
      faqTitle: "بەرەوپێشەکان بەرەوپێشە بەرزەکانت نییە؟",
      faqDescription: "دەتوانیت پەیوەندیدان لەناو ئەم بەرەوپێشەکانە بەرەوپێشە",
      goToCustomerService: "بەرەو مەناچی کۆمپیوتەر",
      contactUsViaWhatsApp: "پەیوەندین لەگەڵ واتساپ",
      faqHeader: "پرسەوەڵەکان",
      faqParagraph: "دەتوانی بەرەوپێشەکان بەرەوپێشە لە پرسەوەڵەکانە",
      bookingMethods: "ڕووکاری پەیوەندیدان",
      customerService: "خزمەتی کڕیار",
      touristPlaces: "شوێنەکان بەرەوپێشە",
      contactHeader: "پەیوەندین لەگەڵ ئەم ناوەندە",
      email: "ئیمەیڵ",
      locationDescription: "وصفی تایبەتی",
      location: "شوێن",
      office: "فەرمی 203 / 7",
      locationDetails: "چۆنە وەی شاری خەرنە دێی",
      sendMessage: "ناردنی پەیوەندیدان",
      message: "پەیوەندیدان",
      contactMethods: "دەتوانی پەیوەندیدان لەگەڵ ئەم شێوەیە",
      filterByProvince: "فلتەر بەپەڕە",
      sort: "ترتیب",
      offerEnds: "پێویستە بەرەوپێشە",
      touristAttractions: "بەرزەکانە کەسەکان",
      apply: "پەیوەندیدان",
      discountCode: "رمزەی پێداویستی",
      rate: "نرخی",
      starsAcc: "ستارەکان (کەمترین)",
      total_views: "ئیشکەری شتەکان",
      long: "پێوەندیدان",
      lat: "پێوەندیدانی عیش",
      start_price: "نرخەی پێویستی",
      end_price: "نرخەی کۆی ئیشەکان",
      is_active: "فەرمی",
      smoking_policy: "سیاساتەکان بەرەوپێشە",
      has_pool: "پەیوەندیدانی پەسکە",
      has_gym: "پەیوەندیدانی تەن",
      has_restaurant: "پەیوەندیدانی فەرمی",
      has_bar: "پەیوەندیدانی بار",
      has_brakfast: "پەیوەندیدانی فەرمی",
      reserve_without_credit: "پەیوەندیدان بە بەرەوپێشەی کەرەوە",
      has_sauna: "پەیوەندیدانی ساونا",
      has_room_service: "پەیوەندیدانی خزمەتی ژوور",
      has_sweet_rooms: "پەیوەندیدانی شەربە",
      has_free_parking: "پەیوەندیدانی پارکی فەرمی",
      show_in_website: "بە شێوەی تایبەتی لە ماڵپەڕەکەدا",
      rateHotel: "نرخەی هوتەڵ",
      invalidToken: "تۆکەنی هەڵە",
      alreadyRated: "پێشتر ئەم هوتەڵە نرخ نەکراوە",
      submit: "پێشکەش کردن",
      ratingSubmitted: "پێشکەشی تایبەتی بە سەرکەوتوویی",
      yourOpinion: "نووسینی پەیوەندیدانەکانت",
      guestRatings: "نرخی بەرزەکان",
      exceptional: "کەسەکان",
      reviews: "بە شێوەیەکی تایبەتی",
      viewAllReviews: "بە شێوەی تایبەتی لە گشتی بەرزەکان",
      ratings: "نرخەکان",
      last20Reviews: "بەرەوپێشەکان تایبەتی",
      ratedOn: "نرخی بەرەوپێشە",
      noOffers: "هیچ پیشکەشی نییە",
      favPlaces: "شوێنەکانە تایبەتی",
      favDescription: "بەرزەکان بەرەوپێشە بەرەوپێشە و پەیوەندیدانی تایبەتی",
      noFavPlaces: "هیچ شوێنە تایبەتی نییە",
      addFavFromMain:
        "دەتوانیت شوێنەکانە تایبەتی لە لیستی هوتەلانەکان زیاد بکەیت",
      locationUnavailable: "شوێنە نییە",
      noAvailableServices: "هیچ خزمەتی نییە",
      days: "ڕۆژ",
      hour: "کاتژمێر",
      minutes: "خولەک",
      availableServices: "خزمەتیەکان",
    },
    actions: {
      bookAndViewRoom: "بە شێوەی تایبەتی ژوورەکانەکانت بەرەوپێشە",
      bookNow: "پەیوەندین بەرەوپێشە",
      done: "کۆی کرد",
      deselect: "لابردن",
      search: "گەڕان",
      select_room: "ژوور دیاریبکە",
      deselect_room: "دیاریکردنی ژوور لابدە",
    },
  },
};

const i18n = createI18n({
  legacy: false, // Use Composition API mode
  locale: defaultLanguage,
  fallbackLocale: "ar",
  messages,
});

export default i18n;
