<template>
  <div class="w-full h-full box-border">
    <div class="flex flex-col h-full p-3">
      <div class="flex-1">
        <button
          v-if="$store.getters.userInfo.userAuth.id === comment.client_id"
          @click="deleteComment"
          class="absolute top-0 left-0 p-1 hover:text-red-500"
        >
          <v-icon icon="mdi-delete" />
        </button>
        <div class="text-sm">{{ comment.comment }}</div>
      </div>
      <div class="w-full">
        <div style="font-size: 10px">
          {{ $t("message.ratedOn") }}: {{ formatDate(comment.created_at) }}
        </div>
        <div class="user-rating">
          <div class="user-name">
            <span class="user-container">
              <img class="w-[30px]" src="@/assets/imgs/user.png" alt="" />
            </span>
            <span class="text-sm">{{ comment.client.name }}</span>
          </div>
          <div>
            <span class="rounded-full bg-[#FAFAFA] px-2 text-xs">
              <v-icon
                v-for="star in comment.rate"
                :key="star"
                class="text-orange"
                size="14"
                :icon="star <= comment.rate ? 'mdi-star' : 'mdi-star-outline'"
              ></v-icon>
              <span class="mr-1">{{ comment.rate }}/5</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },
  name: "HotelRatingCommentsComponent",
  data: () => ({
    isLoading: true,
  }),
  methods: {
    formatDate(date) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(date).toLocaleDateString(this.$i18n.locale, options);
    },
    deleteComment() {
      this.isLoading = true;
      this.$store.dispatch("deleteRate", this.comment.id).then(() => {
        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped>
.Rating-container {
  width: 320px;
}
.Rating-container-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #000;
  padding: 15px 15px;
}
.comment-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  direction: rtl;
  background-color: #fafafa;
  height: 100%;
  gap: 15px;
  font-size: 14px;
  padding: 15px;
  margin-top: 10px;
}
.user-rating {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}
.user-name {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  font-size: 16px;
}
.user {
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.user-container {
  text-align: center;
  border-radius: 50%;
}
</style>
