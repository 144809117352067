<template>
  <div :class="[{ rtl: locale === 'ar' || locale === 'kr' }, 'footer-container']">
    <div class="footer-content" :style="locale === 'ar' || locale === 'kr' ? '' : 'flex-direction: row;'">
      <div
        class="right-side-footer"
        :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'"
        :class="{ 'borderRight': locale !== 'ar' }"
      >
        <h1>Bookingiraq</h1>
        <span>
          {{ $t("message.footerDescription") }}
        </span>
        <div class="contact-us">
          <span class="contact-us-text">{{ $t("message.contactUsText") }}</span>
          <div class="slider-buttons">
            <a href="https://www.facebook.com/profile.php?id=61557147908031&mibextid=ZbWKwL">
              <div class="buttons-container">
                <i class="fa-brands fa-facebook-f"></i>
              </div>
            </a>
            <a href="https://www.instagram.com/booking.iraq?igsh=ZjV6eDhyYXQ5c2Vr">
              <div class="buttons-container">
                <i class="fa-brands fa-instagram"></i>
              </div>
            </a>
            <a href="https://wa.me/07744441300">
              <div class="buttons-container">
                <i class="fa-brands fa-whatsapp"></i>
              </div>
            </a>
            <a href="mailto:info@bookingiraq.com">
              <div class="buttons-container">
                <i class="fa-solid fa-envelope"></i>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="left-side" :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'">
        <div class="left-side-list">
          <span class="left-side-header">{{ $t("message.mainLinks") }}</span>
          <span v-for="(item, index) in NavBarContent" :key="index">
            <router-link
              active-class="active"
              class="hover:text-red duration-500"
              :to="item.link"
            >
              <span>{{ $t(`message.${item.title}`) }}</span>
            </router-link>
          </span>
        </div>
        <div class="left-side-list">
          <span class="left-side-header">{{ $t("message.otherLinks") }}</span>
          <span>
            <router-link
              active-class="active"
              class="hover:text-red duration-500"
              to="/terms-and-conditions"
            >
              <span>{{ $t("message.termsAndConditions") }}</span>
            </router-link>
          </span>
          <span>
            <router-link
              active-class="active"
              class="hover:text-red duration-500"
              to="/privacy-policy"
            >
              <span>{{ $t("message.privacyPolicy") }}</span>
            </router-link>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "FooterComp",
  setup() {
    const { locale } = useI18n();
    return { locale };
  },
  data() {
    return {
      NavBarContent: [
        { title: "home", link: "/" },
        { title: "hotels", link: "/hotels?room_no=1&adult_no=1&children_no=0" },
        { title: "offers", link: "/offers" },
        { title: "faq", link: "/faq" },
        { title: "contact", link: "/contact" },
      ],
    };
  },
};
</script>

<style scoped>
.footer-container {
  font-family: "Cairo", sans-serif;
  background: #003b7d;
  color: #fff;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  width: 70%;
  margin: auto;
}

.right-side-footer,
.left-side {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  padding: 20px;
  margin-top: 50px;
}

.right-side-footer {
  width: 100%;
  border-top: 1px solid #fff;
  /* padding-top: 5px; */
}

.left-side {
  width: 100%;
  border-top: 1px solid #fff;
  /* padding-top: 5px; */
}

.left-side-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.left-side-list span {
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
}

.left-side-list span:hover {
  text-decoration: underline;
}

.left-side .left-side-list .left-side-header {
  font-size: 16px;
  font-weight: 900;
  cursor: default;
}

.left-side .left-side-list .left-side-header:hover {
  font-size: 16px;
  font-weight: 900;
  text-decoration: none;
}

.slider-buttons {
  margin-top: 1rem;
  display: flex;
  gap: 10px;
}

.buttons-container {
  background-color: #fff;
  color: #003b7d;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.contact-us {
  display: flex;
  flex-direction: column;
}

.contact-us-text {
  font-weight: 800;
}
@media (max-width: 768px) {
  .right-side-footer {
    border: none;
  }
}

@media (min-width: 768px) {
  .footer-content {
    /* flex-direction: row; */
  }

  .right-side-footer,
  .left-side {
    width: 45%;
  }

  .right-side-footer {
    border-left: 1px solid #fff;
    border-top: none;
    padding-top: 0;
  }

  .left-side {
    border-top: none;
    padding-top: 0;
  }
}
@media (min-width: 900px) {
  .footer-container {
    height: 450px;
  }}

@media (min-width: 1100px) {
  .footer-container {
    height: 450px;
  }

  .footer-content {
    width: 70%;
    margin: auto;
  }

  .right-side-footer,
  .left-side {
    margin-top: 100px;
    padding: 0px 60px;
  }
}
.borderRight {
  border-right: 1px solid #fff;
  border-left: none;
}
</style>
