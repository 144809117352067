<template>
  <Header />
  <HamburgerMenu />
  <div
    id="alert-additional-content-3"
    class="p-4 mb-4 w-full h-full bg-white shadow rounded text-center"
    role="alert"
  >
    <div class="w-full text-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="70"
        class="mx-auto mb-5"
        viewBox="0 0 2048 2048"
      >
        <path
          class="fill-red-500"
          d="M1024 0q141 0 272 36t244 104t207 160t161 207t103 245t37 272q0 141-36 272t-104 244t-160 207t-207 161t-245 103t-272 37q-141 0-272-36t-244-104t-207-160t-161-207t-103-245t-37-272q0-141 36-272t104-244t160-207t207-161T752 37t272-37m113 1024l342-342l-113-113l-342 342l-342-342l-113 113l342 342l-342 342l113 113l342-342l342 342l113-113z"
        />
      </svg>
      <h4 class="fill-red-500 font-bold text-md">
        عذراً، لم يتم تأكيد حجزك
      </h4>
      <div class="text-sm mt-2">
        يرجى التأكد من ملء جميع الحقول المطلوبة والمحاولة مرة أخرى
      </div>
      <div class="flex justify-center gap-2 mt-2">
        <router-link
          to="/my-bookings"
          class="px-4 py-2 bg-[#003b7d] text-sm text-white rounded-full mt-2"
        >
          عرض الحجوزات
        </router-link>
        <router-link
          to="/"
          class="px-4 py-2 border text-sm text-black rounded-full mt-2"
        >
          الرجوع الى الصفحة الرئيسية
        </router-link>
      </div>
    </div>
  </div>
  <Footer />
  <CopyRightComponent />
</template>

<script>
import Header from "../components/Header.vue";
import HamburgerMenu from "../components/HamburgerMenu.vue";
import RoomConfirmation from "../components/BookHotelRoom/RoomConfirmation.vue";
import Footer from "../components/Footer.vue";
import CopyRightComponent from "../components/CopyRightComponent.vue";

export default {
  name: "MainPage",
  /* eslint-disable */
  components: {
    Header,
    HamburgerMenu,
    RoomConfirmation,
    Footer,
    CopyRightComponent,
  },
};
</script>

<style>
#app {
  font-family: Avenir, "Cairo", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}
</style>
