<template>
  <div class="slider">
    <div class="fade-container">
      <div class="slider-text-top">
        <span v-if="page == 'faq'">{{ $t('message.welcomeToHelpCenter') }}</span>
        <span v-if="page == 'offers'">{{ $t('message.currentOffers') }}</span>
        <span v-if="page == 'contact-us'">{{ $t('message.contactUs') }}</span>
        <span v-if="page == 'my-bookings'">{{ $t('message.myBookings') }}</span>
      </div>
      <div class="slider-text-bottom">
        <span v-if="page == 'faq'">{{ $t('message.helpCenterDescription') }}</span>
        <span v-if="page == 'offers'">{{ $t('message.offersDescription') }}</span>
        <span v-if="page == 'contact-us'">{{ $t('message.contactUsDescription') }}</span>
        <span v-if="page == 'my-bookings'">{{ $t('message.myBookingsDescription') }}</span>
      </div>
      <div class="overlay"></div>
      <div class="fade-item">
        <img v-if="page == 'faq' || page == 'contact-us'" src="@/assets/imgs/Faq.jpg" />
        <img v-if="page == 'offers'" src="@/assets/imgs/offers4.jpg" />
        <img v-if="page == 'my-bookings'" src="@/assets/imgs/bookings2.jpg" />
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
  props: {
    page: String,
  },
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
};
</script>

<style scoped>
.fade-container {
  font-family: "Cairo", sans-serif;
  position: relative;
  height: 275px; /* the fixed height from the UI design */
}

.fade-item {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: opacity 1s ease-in-out;
}

.fade-item img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* cover the area without losing aspect ratio */
  display: block;
}

.slider {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.slider-text-top {
  width: 80%;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 1.8em;
  text-align: center;
  z-index: 10;
}

.slider-text-bottom {
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 1em;
  text-align: center;
  z-index: 10;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #003b7d, rgba(11, 104, 175, 0));
  z-index: 5; /* Putting it above the images, below the text */
}

/* Responsive Design */
@media (min-width: 768px) {
  .fade-container {
    height: 300px; /* Increase height for tablets */
  }

  .slider-text-top {
    font-size: 2em;
    width: 60%;
  }

  .slider-text-bottom {
    font-size: 1.2em;
    width: 60%;
  }
}

@media (min-width: 1024px) {
  .fade-container {
    height: 350px; /* Increase height for desktops */
  }

  .slider-text-top {
    font-size: 2.5em;
    width: 50%;
  }

  .slider-text-bottom {
    font-size: 1.5em;
    width: 50%;
  }
}
</style>
