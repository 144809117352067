<template>
  <div :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'" class="box-container shadow">
    <div class="right-side">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3620.194703879777!2d55.318721771240234!3d25.270872116088867!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f734a5c744f37%3A0x6e047855710d82a6!2s25%C2%B016'15.1%22N+55%C2%B019'07.4%22E!5e0!3m2!1sen!2siq!4v1702872957867!5m2!1sen!2siq"
        width="100%"
        height="450"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
    <div class="left-side">
      <div class="input-field">
        <div class="mb-1.5">*{{ $t('message.fullName') }}</div>
        <v-text-field
          :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'"
          :placeholder="$t('message.fullName')"
          density="compact"
          label=""
          variant="outlined"
        ></v-text-field>
      </div>
      <div class="input-field">
        <div class="mb-1.5">*{{ $t('message.email') }}</div>
        <v-text-field
          :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'"
          :placeholder="$t('message.email')"
          density="compact"
          label=""
          variant="outlined"
        ></v-text-field>
      </div>
      <div class="input-field">
        <div class="mb-1.5">{{ $t('message.phone') }}</div>
        <v-text-field
          :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'"
          :placeholder="$t('message.phone')"
          density="compact"
          label=""
          variant="outlined"
        ></v-text-field>
      </div>
      <div class="input-field">
        <div class="mb-1.5">*{{ $t('message.address') }}</div>
        <v-text-field
          :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'"
          :placeholder="$t('message.address')"
          density="compact"
          label=""
          variant="outlined"
        ></v-text-field>
      </div>
      <div style="width: 100%" class="input-field">
        <div class="mb-1.5">{{ $t('message.message') }}</div>
        <v-textarea
          :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'"
          :placeholder="$t('message.message')"
          density="compact"
          label=""
          variant="outlined"
        ></v-textarea>
      </div>
      <div>
        <button
          type="button"
          class="text-white font-medium rounded-full text-sm px-10 py-2.5 text-center"
        >
          {{ $t('message.sendMessage') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { locale } = useI18n();
    return { locale };
  }
};
</script>

<style scoped>
.box-container {
  font-family: "Cairo", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 90%;
  margin: auto;
  margin-bottom: 4rem;
  gap: 25px;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  height: auto;
  z-index: 10;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.left-side {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.right-side {
  width: 50%;
}
.input-field {
  width: 48%;
}
button {
  background-color: #003b7d;
}

/* Responsive Design */
@media (max-width: 767px) {
  .box-container {
    flex-direction: column;
    width: 95%;
  }
  .left-side,
  .right-side {
    width: 100%;
  }
  .input-field {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .box-container {
    flex-direction: column;
    width: 90%;
  }
  .left-side,
  .right-side {
    width: 100%;
  }
  .input-field {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .box-container {
    width: 70%;
  }
}
</style>
