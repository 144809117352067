<template>
  <div>
    <div :class="[{ rtl: locale === 'ar' || locale === 'kr' }, 'card-container', 'shadow bg-white', 'hover:bg-gray-100']">
      <div class="image-container">
        <div>
          <div class="image-text rounded-full" :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'">
            <span><span v-if='hotel.governorate' class="city-text">{{ locale === 'ar' || locale === 'kr' ? hotel.governorate.ar_name:  hotel.governorate.en_name}}</span></span>
          </div>
          <div class="image-icons rounded-full" :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'">
            <span class="rounded-full" @click.stop="toggleFavorite(hotel)">
              <v-icon
                size="20"
                :class="hotel.is_faviorate ? 'text-orange' : 'text-gray'"
                :icon="hotel.is_faviorate ? 'mdi-star' : 'mdi-star-outline'"
              ></v-icon>
            </span>
            <span class="rounded-full">
              <v-icon size="20" class="text-orange" icon="mdi-magnify"></v-icon>
            </span>
          </div>
        </div>
        <img :src="hotel.image" alt="" />
      </div>
      <div class="card-text" :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'">
        <div class="header-text-container">
          <h1 :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'">{{ hotel.title }}</h1>
          <span style="font-size: 10px;" class="font-medium rounded-full text-center start-count">
            <v-icon class="text-orange" size="18" icon="mdi-star"></v-icon>
            <span>{{ hotel.stars }} </span>
          </span>
        </div>
        <span v-if="hotel.ar_description" class="rating-count" style="font-size: 10px;">
          {{locale === 'ar' || locale === 'kr' ? hotel.ar_description.slice(0, 212): hotel.en_description.slice(0, 212) }}...
        </span>
        <span v-else class="rating-count" style="font-size: 10px;">
          {{ $t('message.noDescription') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default {
  props: {
    hotel: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { locale } = useI18n();
    const store = useStore();

    const toggleFavorite = async (hotel) => {
      try {
        await store.dispatch("addOrRemoveFavorite", { hotel_id: hotel.id });
        hotel.is_faviorate = !hotel.is_faviorate;
      } catch (error) {
        console.error("Error toggling favorite:", error);
      }
    };

    return { locale, toggleFavorite };
  },
};
</script>

<style scoped>
.card-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 320px;
  width: 230px;
  border-radius: 10px;
}

.card-container:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: all 0.4s ease-in-out;
}

.card-text {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 16px;
  justify-content: space-between;
  padding: 10px 15px 15px 15px;
  gap: 8px;
  width: 100%;
  height: 100%;
}

.image-container {
  height: 180px;
  width: 230px;
  margin: 5px;
  position: relative;
}

.image-container img {
  width: 100%;
  border-radius: 10px;
  height: 150px;
  object-fit: cover;
  display: block;
}

.header-text-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.start-count {
  font-size: 16px;
  background-color: #fafafa;
  padding: 2px 8px;
  display: flex;
  align-items: center;
  gap: 1px;
}

.rating-count {
  font-size: 12px;
  margin-right: 3px;
}

.image-text {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 5px 12px;
  margin: 5px;
  font-size: 12px;
}

.image-icons {
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  margin: 5px;
  font-size: 12px;
  display: flex;
  gap: 4px;
}

.image-icons span {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 6px;
}

.city-text {
  color: #ffb325;
}

.text-gray {
  color: gray;
}
</style>
