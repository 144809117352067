<template>
  <div :class="[{ rtl: locale === 'ar' || locale === 'kr' }, 'containerNav']">
    <div class="content">
      <div class="left-header">
        <div class="notification"></div>
        <div
          class="Userdiv"
          style="cursor: pointer"
          v-if="userName && userName !== 'Guest User'"
          :dir="locale === 'en' ? 'rtl' : 'ltr'"
          @click="toggleCard"
        >
          <span class="user-containerNav mx-2">
            <img class="user" :src="userInfo.userAuth.image || '/assets/images/user.png'" alt="" />
          </span>
          <span
            style="font-weight: bold; font-size: 13px"
            v-if="userName"
            :title="userName"
          >
            <span v-if="userName == 'Guest User'">{{
              $t("message.GuestUser")
            }}</span>
            <span v-else>{{
              userName.length > 15
                ? userName.substring(0, 15) + "..."
                : userName
            }}</span>
          </span>
          <i class="fa-solid fa-caret-down"></i>
          <v-card ref="peopleCard" class="people-card" v-show="cardVisible">
            <router-link to="/account-setting">
              <div class="p-4 list-choice">
                {{ $t("message.accountSettings") }}
                <i class="fa-regular fa-user mt-1 mx-3"></i>
              </div>
            </router-link>
            <router-link to="/my-bookings">
              <div class="p-4 list-choice">
                {{ $t("message.bookings") }}
                <i class="fa-regular fa-handshake mt-1 mx-3"></i>
              </div>
            </router-link>
            <router-link to="/favorites">
              <div class="p-4 list-choice">
                {{ $t("message.favorites") }}
                <i class="fa-regular fa-star mt-1 mx-3"></i>
              </div>
            </router-link>
            <div @click="postLogout" class="p-4 list-choice">
              {{ $t("message.logout") }}
              <i class="fa-solid fa-sign-out-alt mt-1 mx-3"></i>
            </div>
          </v-card>
        </div>
      </div>
      <div class="center-header">
        <ul>
          <li v-for="(item, index) in NavBarContent" :key="index">
            <div v-if="index !== 1">
              <router-link
                active-class="active"
                class="hover:text-red duration-500 px-2"
                :to="item.link"
              >
                <span>{{ $t(item.title) }}</span>
              </router-link>
            </div>
            <div
              class="hover:text-red duration-500 px-2"
              v-if="index === 1"
              @click="search"
            >
              {{ $t(item.title) }}
            </div>
          </li>
        </ul>
      </div>

      <router-link to="/">
        <div class="right-header">{{ $t("message.bookingIraq") }}</div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { ref, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

export default {
  name: "NavBar",
  setup() {
    const { t, locale } = useI18n();
    const store = useStore();
    const router = useRouter();
    // const cardVisible = ref(false);
    const room_no = ref(0);
    const adult_no = ref(0);
    const children_no = ref(0);

    const NavBarContent = ref([
      { title: "message.home", link: "/" },
      { title: "message.hotels", link: "/hotels" },
      { title: "message.offers", link: "/offers" },
      { title: "message.faq", link: "/faq" },
      { title: "message.contact", link: "/contact" },
    ]);

    const userName = ref("");
    const cardVisible = ref(false);

    const postLogout = async () => {
      try {
        await store.dispatch("logOut");
        router.push("/login");
      } catch (error) {
        console.error("Logout error:", error);
      }
    };

    const toggleCard = () => {
      if (userName.value === "Guest User") return;
      cardVisible.value = !cardVisible.value;
    };
    const search = () => {
      router.push({
        name: "Hotels",
        query: {
          room_no: room_no.value ? room_no.value : 1,
          adult_no: adult_no.value ? adult_no.value : 1,
          children_no: children_no.value,
        },
      });
    };

    onBeforeMount(async () => {
      const userInfo = store.getters.userInfo;
      if (!userInfo) {
        try {
          await store.dispatch("loginGuest"); // Wait for guest login to complete
          userName.value = "Guest User";
        } catch (error) {
          console.error("Login error:", error);
        }
      } else {
        userName.value = userInfo.userAuth.is_guest
          ? "Guest User"
          : userInfo.userAuth.name;
      }
    });

    return {
      NavBarContent,
      userName,
      postLogout,
      toggleCard,
      cardVisible,
      t,
      locale,
      search,
    };
  },
  beforeCreate() {
    // Example prompting for notification permission
    if (
      this.$store.getters.userInfo &&
      !this.$store.getters.userInfo.userAuth.is_guest
    ) {
      this.$OneSignal.User.PushSubscription.optIn().then(() => {
        // console.log(this.$OneSignal.User.PushSubscription.id);
        this.$store.dispatch("updateAuthUserSubscriptionID", {
          subscription_id: this.$OneSignal.User.PushSubscription.id,
        });
      });
    }
  },
};
</script>

<style scoped>
.note {
  background-color: #0091ff;
  color: #fff;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.containerNav {
  font-family: "Cairo", sans-serif;
  display: flex;
  align-items: center;
  background-color: #fff;
  height: 92px;
}
.rtl {
  direction: rtl;
}
.content {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin: auto;
  color: #000;
}
.right-header {
  font-size: x-large;
  font-weight: bolder;
}
.center-header ul {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-align: center;
}
.center-header li {
  display: block;
  color: #333;
  text-align: center;
  font-weight: 600;
  padding: 14px 16px;
  text-decoration: none;
  cursor: pointer;
}
.center-header li a:hover {
  color: #0091ff;
}
.center-header li div:hover {
  color: #0091ff;
}
.left-header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 22%;
}
.fa-message,
.fa-bell {
  font-size: x-large;
  line-height: 40px;
  text-align: center;
  width: 40px;
  height: 40px;
  border: #003b7d 2px solid;
  color: #003b7d;
  border-radius: 50%;
}
.notification {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  width: 40%;
  align-items: center;
}
.Userdiv {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  margin-left: 0.5rem;
  width: 100%;
  align-items: center;
}
.user {
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.user-containerNav {
  text-align: center;
  border-radius: 50%;
}
.people-card {
  position: absolute;
  width: 200px;
  top: 130px;
  z-index: 1000;
}
.list-choice {
  display: flex;
  justify-content: flex-end;
}
.list-choice:hover {
  cursor: pointer;
  background: #dfdfdf;
  transition: all 0.5s ease-in-out;
}
@media (max-width: 1550px) {
  .right-header {
    font-size: x-large;
  }
  .center-header li {
    padding: 5px;
    font-size: 14px;
  }
  .left-header {
    justify-content: flex-start;
    gap: 5px;
    width: 25%;
  }
}
@media (max-width: 1350px) {
  .right-header {
    font-size: large;
  }
  .content {
    width: 90%;
  }
  .left-header {
    justify-content: flex-start;
    gap: 5px;
    width: 25%;
  }
}
</style>
