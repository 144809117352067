<template>
  <div class="container" v-if="showOffers">
    <div class="container-header">
      <div class="typing" dir="rtl">
        <span class="typing-header">
          {{ slide[`${locale}_title`] }}
        </span>
        <span class="typing-pargraph">
          {{ slide[`${locale}_description`] }}
        </span>
      </div>
      <div class="slider-buttons">
        <div class="buttons-container" @click="moveCards('right')">
          <i class="fa-solid fa-arrow-right-long"></i>
        </div>
        <div class="buttons-container" @click="moveCards('left')">
          <i class="fa-solid fa-arrow-left-long"></i>
        </div>
      </div>
    </div>
    <div class="content" ref="cardContainer">
      <div @click="search(item)" v-for="(item, index) in sliders" :key="index">
        <div class="card-container shadow hover:bg-gray-100">
          <div class="image-container">
            <div>
              <div class="image-text rounded-full" dir="rtl">
                <span><span class="city-text">بغداد</span></span>
              </div>
              <div class="image-icons rounded-full" dir="rtl">
                <span class="rounded-full">
                  <v-icon
                    size="20"
                    class="text-orange"
                    icon="mdi-star-outline"
                  ></v-icon>
                </span>
                <span class="rounded-full">
                  <v-icon
                    size="20"
                    class="text-orange"
                    icon="mdi-magnify"
                  ></v-icon>
                </span>
              </div>
            </div>
            <img :src="item.image" alt="" />
          </div>
          <div class="card-text">
            <div class="header-text-container">
              <h1 dir="rtl">{{ item.title }}</h1>
              <span class="font-medium rounded-full text-center start-count">
                <v-icon class="text-orange" size="18" icon="mdi-star"></v-icon
                ><span>{{ item.stars }}</span>
              </span>
            </div>
            <div>
              <v-icon
                class="text-orange"
                v-for="(starItem, indexItem) in item.stars"
                :key="indexItem"
                size="14"
                icon="mdi-star"
              ></v-icon>
              <span class="rating-count">{{ item.stars }}</span>
            </div>
            <span class="rating-count"
              >{{ item.ar_description?.slice(0, 255) }}...
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="show-more-button">
      <button
        type="button"
        class="text-white bg-[#003b7d] hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-6 py-3 text-center"
      >
        عرض المزيد
      </button>
    </div> -->
  </div>
</template>

<script setup>
import { ref, defineProps, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

// Define props
const props = defineProps(["slide"]);
const sliders = ref([]);
const router = useRouter();
const cardContainer = ref(null);
const showOffers = ref(true); // Initialize showOffers with true initially
const adult_no = ref(1);
const children_no = ref(0);
const governorate = ref("");
const checkin = ref("");
const checkout = ref("");
const room_no = ref(1);
const {locale} = useI18n();

const search = (item) => {
  router.push({
    name: "Hotel",
    query: {
      id: item.id,
      governorate: governorate.value,
      checkin: checkin.value,
      checkout: checkout.value,
      room_no: room_no.value,
      adult_no: adult_no.value,
      children_no: children_no.value,
    },
  });
};
onBeforeMount(async () => {
  try {
    sliders.value = props.slide.sliders
      .map((offer) => offer.child)
      .filter((child) => child !== null);
    if (sliders.value.length) {
      showOffers.value = true;
    } else {
      showOffers.value = false;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
});

// Define the method to move cards
const moveCards = (direction) => {
  const container = cardContainer.value;
  const cardWidth = container.offsetWidth; // Adjust this value based on your card width

  if (direction === "left") {
    container.scrollTo({
      left: container.scrollLeft - cardWidth,
      behavior: "smooth",
    });
  } else if (direction === "right") {
    container.scrollTo({
      left: container.scrollLeft + cardWidth,
      behavior: "smooth",
    });
  }
};
</script>

<style scoped>
.container {
  margin: auto;
  font-family: "Cairo", sans-serif;
  width: 70%;
  /* height: 620px; */
  margin-bottom: 3rem;
  direction: rtl;
}
.content {
  /* height:400px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* flex-wrap: wrap;  */
  overflow: hidden;
  gap: 20px;
  padding: 1rem;
}
.card-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 175;
  width: 315px;
  border-radius: 10px;
}
.card-container:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: all 0.4s ease-in-out;
}
.card-text {
  display: flex;
  flex-direction: column;
  align-items: right;
  font-size: 16px;
  justify-content: space-between;
  padding: 10px 15px 15px 15px;
  gap: 8px;
  width: 100%;
  height: 100%;
}
.image-container {
  height: 230px;
  width: 315px;
  position: relative;
}
.image-container img {
  width: 100%;
  border-radius: 10px;
  height: 100%;
  object-fit: cover;
  display: block;
}
.typing {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.typing-header {
  font-size: 26px;
  color: #ffb325;
  font-weight: bold;
}
.typing-pargraph {
  font-size: 14px;
  color: #000;
}
.time-text {
  color: #ffb325;
  font-size: 14px;
  background-color: #fafafa;
}
.show-more-button {
  margin-top: 30px;
}
.show-more-button button {
  background-color: #003b7d;
}
.show-more-button button:hover {
  background-color: #043961;
  transition: all 0.5s ease-in-out;
}
.container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.slider-buttons {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  gap: 10px;
}
.buttons-container {
  background-color: #003b7d;
  color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.buttons-container:hover {
  background-color: #043961;
  transform: scale(1.05);
  transition: all 0.4s ease-in-out;
}
.header-text-container {
  display: flex;
  justify-content: space-between;
}
.start-count {
  font-size: 16px;
  background-color: #fafafa;
  padding: 2px 8px;
  display: flex;
  align-items: center;
  gap: 1px;
}
.rating-count {
  font-size: 12px;
  margin-right: 3px;
}
.image-text {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Background color with some transparency */
  color: #fff;
  padding: 5px 12px;
  margin: 5px; /* Remove any default margin on h1 */
  font-size: 12px; /* Adjust font size as needed */
}
.image-icons {
  position: absolute;
  top: 0;
  left: 0;
  /* background-color: rgba(0, 0, 0, 0.5);  */
  color: #fff;
  margin: 5px; /* Remove any default margin on h1 */
  font-size: 12px; /* Adjust font size as needed */
  display: flex;
  gap: 4px;
}
.image-icons span {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 6px;
}
.city-text {
  color: #ffb325;
}
</style>
