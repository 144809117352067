<template>
  <div
    class="w-[90%] md:w-[70%] mx-auto mt-5"
    :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'"
  >
    <div class="w-full bg-white rounded-lg py-2 flex justify-between items-center px-2 shadow">
      <div class="text-xs p-3 rounded-lg">
        <span style="color: orange">{{ $t("message.home") }}</span> \
        <span style="color: orange">{{ $t("message.hotelList") }}</span> \
        <span>
          {{ hotelsInfo.title }}
        </span>
      </div>
      <div class="flex-inline items-center justify-center">
        <span class="rounded-full text-center text-xs flex-inline items-center justify-center bg-[#fafafa] px-2 mx-2">
          <v-icon class="text-orange" size="14" icon="mdi-star"></v-icon>
          <span>
            {{ hotelsInfo.stars }}
          </span>
        </span>
        <span class="mrounded-full flex-inline items-center justify-center bg-[#fafafa] text-xs px-2 rounded-full">
          <v-icon
            class="text-orange"
            size="14"
            icon="mdi-star"
            v-for="(item, index) in hotelsInfo.rate"
            :key="index"
          ></v-icon>
          <span class="mx-1">{{ hotelsInfo.rate }}/5</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import { ref, defineProps } from "vue";
import { useI18n } from "vue-i18n";

const { locale } = useI18n();
const props = defineProps(["hotelsData"]);
const hotelsInfo = ref({});

const showHotel = async () => {
  try {
    hotelsInfo.value = props.hotelsData;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

onMounted(showHotel);
</script>
