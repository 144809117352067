<template>
  <Header />
  <HamburgerMenu />
  <RoomConfirmation />
  <Footer />
  <CopyRightComponent />
</template>

<script>
import Header from "../components/Header.vue";
import HamburgerMenu from "../components/HamburgerMenu.vue";
import RoomConfirmation from "../components/BookHotelRoom/RoomConfirmation.vue";
import Footer from "../components/Footer.vue";
import CopyRightComponent from "../components/CopyRightComponent.vue";

export default {
  mounted() {
    window.scrollTo(0, 0);
  },
  name: "MainPage",
  /* eslint-disable */
  components: {
    Header,
    HamburgerMenu,
    RoomConfirmation,
    Footer,
    CopyRightComponent,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}
</style>
