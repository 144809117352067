<template>
  <Header />
  <HamburgerMenu />
  <div
    id="alert-additional-content-3"
    class="p-4 mb-4 w-full h-full bg-white shadow rounded text-center"
    role="alert"
  >
    <div class="w-full text-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="80"
        fill="#16a085"
        class="mx-auto"
      >
        <path
          d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z"
        ></path>
      </svg>
      <h4 class="text-[#16a085] font-bold text-md">
        مبروك، تم تأكيد حجزك بنجاح
      </h4>
      <div class="text-sm mt-2">يمكنك متابعة تفاصيل الحجز من صفحة الحجوزات</div>
      <div class="flex justify-center gap-2 mt-2">
        <router-link
          to="/my-bookings"
          class="px-4 py-2 bg-[#003b7d] text-sm text-white rounded-full mt-2"
        >
          عرض الحجوزات
        </router-link>
        <router-link
          to="/"
          class="px-4 py-2 border text-sm text-black rounded-full mt-2"
        >
          الرجوع الى الصفحة الرئيسية
        </router-link>
      </div>
    </div>
  </div>
  <Footer />
  <CopyRightComponent />
</template>

<script>
import Header from "../components/Header.vue";
import HamburgerMenu from "../components/HamburgerMenu.vue";
import RoomConfirmation from "../components/BookHotelRoom/RoomConfirmation.vue";
import Footer from "../components/Footer.vue";
import CopyRightComponent from "../components/CopyRightComponent.vue";

export default {
  name: "MainPage",
  /* eslint-disable */
  components: {
    Header,
    HamburgerMenu,
    RoomConfirmation,
    Footer,
    CopyRightComponent,
  },
};
</script>

<style>
#app {
  font-family: Avenir, 'Cairo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}
</style>
