import $http from "@/plugins/axios.js";

export default {
  state: {
    users: [],
    faq: [],
    offers: [],
    discounts: [],
    currency: [],
    hotels: [],
    hotel: [],
    sliders: [],
    governorate: [],
    currency_selected: localStorage.getItem("currency_selected") || "USD",
    reservations: [],
    reservationsList: [],
    faviorate: [],
    search_results: [],
    comments: [],
    start_price: 0,
    end_price: 500,
    totalReviews: 0,
    bookingData: {
      full_name: "",
      email: "",
      phone: "",
      country: "",
    },
  },
  getters: {
    users: (state) => state.users,
    faq: (state) => state.faq,
    offers: (state) => state.offers,
    discounts: (state) => state.discounts,
    hotels: (state) => state.hotels,
    sliders: (state) => state.sliders,
    governorate: (state) => state.governorate,
    hotel: (state) => state.hotel,
    currency: (state) => state.currency,
    currency_selected: (state) => state.currency_selected,
    faviorate: (state) => state.faviorate,
    search_results: (state) => state.search_results,
    comments: (state) => state.comments,
    totalReviews: (state) => state.totalReviews,
  },
  actions: {
    setSelectedCurrency({ commit }, data) {
      commit("SET_SELECTED_CURRENCY", data);
    },
    async searchHotels({ commit }, filterData = { search: "Baghdad" }) {
      const response = await $http.get(`/search?search=${filterData.search}`);
      commit("SET_SEARCH", response.data);
      return response.data;
    },
    async getFaviorates({ commit }, filterData = { take: 15, skip: 0 }) {
      const response = await $http.get(
        `/my-faviorate?take=${filterData.take}&skip=${filterData.skip}&include=governorate`
      );
      commit("SET_FAVIORATE", response.data);
    },
    async getFaq(
      { commit },
      filterData = { take: 15, skip: 0, department_type: 1 }
    ) {
      try {
        const response = await $http.get(
          `/common-questions?take=${filterData.take}&skip=${filterData.skip}&filter[department_type]=${filterData.department_type}`
        );
        commit("SET_FAQ", response.data);
      } catch (error) {
        console.error("Error fetching FAQ:", error);
      }
    },
    async getOffer(
      { commit },
      filterData = { take: 15, page: 1, governorate_id: "" }
    ) {
      const response = await $http.get(
        `/offers?take=${filterData.take}&page=${filterData.page}&filter[governorate_id]=${filterData.governorate_id}`
      );
      commit("SET_OFFERS", response.data);
    },
    async getDiscounts({ commit }, filterData = { take: 15, skip: 0 }) {
      const response = await $http.get(
        `/discounts?take=${filterData.take}&skip=${filterData.skip}`
      );
      commit("SET_DISCOUNTS", response.data);
    },
    async getCurrency({ commit }) {
      const response = await $http.get(`/currency`);
      commit("SET_CURRENCY", response.data);
    },
    async getHotels({ commit }, filterData) {
        const {
        take = 9,
        page = 1,
        ...filters
      } = filterData;
      this.room_number = filters.room_number??1
      this.adult_number = filters.adult_number??1
      this.children_number = filters.children_number??0
      this.currency = (filters.currency === 'undefined' || filters.currency === 'null') ? 'USD' : filters.currency
      this.governorate = filters.governorate??""
      this.start_price = filters.start_price??0
      this.end_price = filters.end_price?? 500
      const filterParams = Object.keys(filters)
        .filter((key) => filters[key] && key !== "end_price" && key !== "start_price" && key !== "room_number" && key !== "adult_number" && key !== "children_number" && key !== "currency" && key !== "governorate")
        .map((key) => `filter[${key}]=${(filters[key] === 'undefined' || filters[key] === 'null') ? '' : filters[key]}`)
        .join("&");
      const response = await $http.get(
        `/hotel?take=${take}&page=${page}&filter[governorate_id]=${this.governorate}&start_price=${this.start_price}&end_price=${this.end_price}&checkin=${filters.checkin}&checkout=${filters.checkout}&room_number=${this.room_number}&adult_number=${this.adult_number}&children_number=${this.children_number}&currency=${filters.currency}&${filterParams}&sort=-id&include=city,governorate`
      );
      commit("SET_HOTELS", response.data);
      return response.data;
    },
    async getHotel(
      { commit },
      filterData
    ) {
      const response = await $http.get(
        `/hotel/${filterData.id}?checkin=${filterData.checkin}&checkout=${filterData.checkout}&room_number=${filterData.room_number}&adult_number=${filterData.adult_number}&children_number=${filterData.children_number}&currency=${filterData.currency}&sort=-id&include=city,governorate`
      );
      commit("SET_HOTEL", response.data);
      return response.data;
    },
    async getSliders({ commit }, filterData = { take: 9, skip: 0 }) {
      const response = await $http.get(
        `/slider?take=${filterData.take}&skip=${filterData.skip}&include=sliders`
      );
      commit("SET_SLIDERS", response.data);
      return response.data;
    },
    async getGovernorates({ commit }, filterData = { take: 30 }) {
      const response = await $http.get(`/governorates?take=${filterData.take}`);
      commit("SET_GOVERNORATES", response.data);
      return response.data;
    },
    async getMyReservations(
      { commit },
      filterData = { take: 30, is_paid: null, is_current: 1 }
    ) {
      const response = await $http.get(
        `/my-reservations?take=${filterData.take}&sort=-id`,
        {
          params: {
            is_not_paid: filterData.is_paid,
            is_current: filterData.is_current
          }
        }
      );
      commit("SET_RESERVATIONS", response.data);
      return response.data;
    },
    async makeReservation({ commit }, data) {
      const response = await $http.post("/make-reservation", data);
      commit("MAKE_RESERVATION", response.data);
      return response.data;
    },
    async createUser({ commit }, user) {
      const response = await $http.post("/user", user);
      commit("ADD_NEW_USER", response.data.data);
    },
    async createFaviorate({ commit }, data) {
      const response = await $http.post("/add-or-remove-from-faviorate", data);
      commit("ADD_NEW_FAVIORATE", response.data.data);
    },
    async updateUser({ commit }, user) {
      const response = await $http.post(`/user/${user.id}`, user);
      commit("UPDATE_USER", response.data);
    },
    async removeUser({ commit }, id) {
      await $http.delete(`/user/${id}`);
      commit("REMOVE_USER", id);
    },
    async getComments({ commit }, hotelId) {
      const response = await $http.get(`/comment?hotel_id=${hotelId}`);
      commit("SET_COMMENTS", response.data);
    },
    async addOrRemoveFavorite({ commit }, hotel_Id) {
      try {
        const response = await $http.post(
          "/add-or-remove-from-faviorate",
          hotel_Id
        );
        commit("UPDATE_FAVORITE_STATUS", response.data);
      } catch (error) {
        console.error("Error adding/removing favorite:", error);
      }
    },
    updateBookingField({ commit }, { field, value }) {
      commit("UPDATE_BOOKING_FIELD", { field, value });
    },
    async confirmBooking({ state }, reservationId) {
      const bookingDetails = state.bookingData;
      // Send a POST request to the API with the booking details and reservation_id
      const response = await $http.post(
        `/confirm/reservation/${reservationId}`,
        bookingDetails
      );
      // Return the API response
      return response;
    },
    async downloadReservation({ commit }, payload) {
      const response = await $http.get(`/download/${payload.id}`, {
        responseType: "blob",
      });
      const blob = new Blob([response.data], {
        type: "application/pdf",
      });
      // Create a temporary anchor element
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);

      // Set the download attribute with the desired file name
      downloadLink.setAttribute("download", payload.reservation_no + ".pdf");

      // Append the anchor element to the document body
      document.body.appendChild(downloadLink);

      // Trigger a click event to prompt the user to save the file
      downloadLink.click();

      // Remove the anchor element from the document
      document.body.removeChild(downloadLink);
      commit("UPDATE_RESERVATIONS", response.data.data);
    },
    async validateRateToken(_ctx, revId) {
      return await $http.get(`/comment-rate/${revId}`);
    },
    async sendRate(_ctx, payload) {
      return await $http.post(`/comment-rate/${payload.id}`, payload);
    },
    async deleteRate(_ctx, payload) {
      return await $http.delete(`/comment/${payload}`);
    },
  },
  mutations: {
    UPDATE_BOOKING_FIELD(state, { field, value }) {
      state.bookingData[field] = value;
    },
    SET_SEARCH(state, data) {
      state.search_results = data;
    },
    SET_FAVIORATE(state, data) {
      state.faviorate = data;
    },
    SET_FAQ(state, data) {
      state.faq = data;
    },
    SET_OFFERS(state, data) {
      state.offers = data;
    },
    SET_DISCOUNTS(state, data) {
      state.discounts = data;
    },
    SET_CURRENCY(state, data) {
      state.currency = data;
    },
    SET_SELECTED_CURRENCY(state, data) {
      state.currency_selected = data;
      localStorage.setItem("currency_selected", data);
    },
    SET_HOTELS(state, data) {
      state.hotels = data;
    },
    SET_HOTEL(state, data) {
      state.hotel = data;
    },
    SET_SLIDERS(state, data) {
      state.sliders = data.data.map((slider) => ({
        sliders: slider.sliders,
        ar_title: slider.ar_title,
        slider_type: slider.slider_type,
      }));
    },
    SET_GOVERNORATES(state, data) {
      state.governorate = data.data;
    },
    SET_RESERVATIONS(state, data) {
      state.reservationsList = data;
    },
    ADD_NEW_FAVIORATE(state, data) {
      state.data.push(data);
    },
    ADD_NEW_USER(state, data) {
      state.users.data.push(data);
    },
    MAKE_RESERVATION(state, data) {
      state.reservations.push(data);
    },
    UPDATE_USER(state, data) {
      state.users.data = state.users.data.map(
        (item) => (item = item.id == data.id ? data : item)
      );
    },
    REMOVE_USER(state, id) {
      state.users.data = state.users.data.filter((item) => item.id !== id);
    },
    SET_COMMENTS(state, data) {
      state.comments = data.data;
      state.totalReviews = data.total;
    },
    UPDATE_FAVORITE_STATUS(state, data) {
      // Update the state based on the response. This is a placeholder.
      // You may need to adjust this logic based on how your API response looks.
      const index = state.hotels.findIndex(
        (hotel) => hotel.id === data.hotel_id
      );
      if (data.message.slice(0, 7) == "Removed") {
        state.faviorate.data = state.faviorate.data.filter(
          (item) => item.id !== data.data.id
        );
      }
      if (index !== -1) {
        state.hotels[index].is_favorites = data.is_favorites;
      }
    },
  },
};
