<template>
  <NavBar v-if="isDesktop" />
  <div
    v-if="!isDesktop"
    class="NavContainer shadow"
  >
    <div class="content">
      <div class="right-header">Bookingiraq</div>
      <v-app-bar-nav-icon @click="toggleDrawer" v-if="!isDesktop" />
    </div>

    <div class="overlay" v-if="drawer" @click="closeDrawer"></div>

    <div class="side-menu" :class="{ open: drawer }" ref="sideMenu">
      <ul>
        <li v-for="(item, index) in NavBarContent" :key="index">
          <router-link
            active-class="active"
            class="hover:text-red duration-500 px-2"
            :to="item.link"
            @click="closeDrawer"
          >
            <span>{{ item.title }}</span>
          </router-link>
        </li>
      </ul>
      <div class="footer-links">
        <router-link
          active-class="active"
          class="footer-link"
          to="/login"
          @click="closeDrawer"
        >
          <span>تسجيل الدخول</span>
        </router-link>
        <router-link
          active-class="active"
          class="footer-link"
          to="/signup"
          @click="closeDrawer"
        >
          <span>انشاء حساب</span>
        </router-link>
      </div>
      <div class="bottom-links">
        <router-link
          active-class="active"
          class="footer-link2"
          to="/terms-and-conditions"
          @click="closeDrawer"
        >
          <span>الشروط و الاحكام</span>
        </router-link>
        <router-link
          active-class="active"
          class="footer-link2"
          to="/privacy-policy"
          @click="closeDrawer"
        >
          <span>سياسة الخصوصية</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "../components/NavBar.vue";

export default {
  /* eslint-disable */
  name: "HamburgerMenu",
  components: {
    NavBar,
  },
  data() {
    return {
      drawer: false,
      isDesktop: true, // Set to true by default, and update based on screen width
      menuItems: ["Home", "About", "Contact"],
      NavBarContent: [
        {
          title: "الرئيسية",
          link: "/",
        },
        {
          title: "قائمة الفنادق",
          link: "/hotels?room_no=1&adult_no=1&children_no=0",
        },
        {
          title: "الجوائز والعروض",
          link: "/offers",
        },
        {
          title: "الاسئلة الشائعة",
          link: "/faq",
        },
        {
          title: "تواصل معنا",
          link: "/contact",
        },
      ],
    };
  },
  mounted() {
    this.updateIsDesktop(); // Check screen width on mount
    window.addEventListener("resize", this.updateIsDesktop); // Update on window resize
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateIsDesktop); // Remove event listener on component destroy
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
      if (this.drawer) {
        document.addEventListener("click", this.handleClickOutside);
      } else {
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    closeDrawer() {
      this.drawer = false;
      document.removeEventListener("click", this.handleClickOutside);
    },
    handleClickOutside(event) {
      if (this.$refs.sideMenu && !this.$refs.sideMenu.contains(event.target)) {
        if (event.layerX > 220) {
          this.closeDrawer();
        }
      }
    },
    updateIsDesktop() {
      this.isDesktop = window.innerWidth >= 1060;
    },
  },
};
</script>

<style scoped>
.NavContainer {
  font-family: "Cairo", sans-serif;
  display: flex;
  align-items: center;
  background-color: #fff;
  height: 92px;
  width: 100%;
}
.content {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: auto;
  color: #000;
}
.right-header {
  font-size: x-large;
  font-weight: bolder;
}
.side-menu ul {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 2rem;
}
.side-menu li {
  display: block;
  color: #333; /* Text color for the links */
  text-align: center;
  font-weight: 600;
  padding: 14px 16px; /* Padding to increase the clickable area */
  text-decoration: none; /* Removes the underline from links */
  cursor: pointer;
}

/* Change the link color on hover */
.side-menu li a:hover {
  color: #0091ff;
}
.footer-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid #ddd;
}
.bottom-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid #ddd;
  margin-top: auto;
}
.footer-link2 {
  display: block;
  color: #333;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  padding: 8px 0;
  text-decoration: none;
  cursor: pointer;
}
.footer-link2:hover {
  color: #0091ff;
}
.footer-link {
  display: block;
  color: #333;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  padding: 8px 0;
  text-decoration: none;
  cursor: pointer;
}
.footer-link:hover {
  color: #0091ff;
}

.left-header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  width: 22%;
}
.fa-message,
.fa-bell {
  font-size: x-large;
  line-height: 40px;
  text-align: center;
  width: 40px;
  height: 40px;
  border: #003b7d 2px solid;
  color: #003b7d;
  border-radius: 50%;
}
.notification {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  width: 40%;
  align-items: center;
}
.Userdiv {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  margin-left: 0.5rem;
  width: 60%;
  align-items: center;
}
.user {
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.user-container {
  text-align: center;
  border-radius: 50%;
}
.active {
  color: #0091ff;
}

@media (max-width: 1250px) {
  .right-header {
    font-size: large;
  }
  .content {
    width: 90%;
  }
  .left-header {
    justify-content: flex-start;
    gap: 5px;
    width: 25%;
  }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: none;
}

.side-menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 220px; /* Adjust the width as needed */
  background-color: #fff;
  z-index: 1000;
  transform: translateX(-300px);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
}

.side-menu.open {
  transform: translateX(0);
}
</style>
