<template>
  <div class="faq-container">
    <div v-if="loading" class="skeleton-loader">
      <div class="skeleton-item" v-for="n in 5" :key="n"></div>
    </div>
    <div v-else>
      <div v-if="faq.length === 0" class="show-list-container2">
        <v-card class="hotel-not-found">
          <div>
            <v-icon class="text-orange" size="80" icon="mdi-alert-circle"></v-icon>
          </div>
          <div style="font-size: 28px; color: green">{{ $t("message.noFaq") }}</div>
        </v-card>
      </div>
      <div v-else>
        <div v-for="(item, index) in faq" :key="index">
          <div class="card-container shadow hover:bg-gray-100" @click="toggleInfo(index)">
            <span class="px-4"> {{ locale === 'ar' || locale === 'kr' ? item.ar_question : item.en_question }}</span>
            <span>
              <v-icon
                v-if="!item.showInfo"
                class="ml-2"
                size="large"
                icon="mdi-chevron-down"
              ></v-icon>
              <v-icon
                v-if="item.showInfo"
                class="ml-2"
                size="large"
                icon="mdi-chevron-left"
              ></v-icon>
            </span>
          </div>
          <div v-if="item.showInfo" class="description-container">
            {{ locale === 'ar' || locale === 'kr' ? item.ar_answer : item.en_answer }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: 'ListOfQuestions',
  props: {
    faq: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    const { locale } = useI18n();
    return { locale };
  },
  methods: {
    toggleInfo(index) {
      this.$emit('toggle-info', index);
    }
  },
  watch: {
    faq: {
      handler(newVal) {
        console.log('FAQ data updated in child component:', newVal);
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.faq-container {
  margin-top: 2rem;
  margin-bottom: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
}
.card-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 62px;
  border-radius: 2px;
}
.card-container:hover {
  cursor: pointer;
  transform: scale(1.01);
  transition: all 0.4s ease-in-out;
}
.description-container {
  background: #f4f4f4;
  border-radius: 5px;
  padding: 1rem;
}
.skeleton-loader {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.skeleton-item {
  height: 62px;
  background: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s infinite;
}
@keyframes pulse {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f0f0f0;
  }
  100% {
    background-color: #e0e0e0;
  }
}
.no-data {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #777;
}
.hotel-not-found {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 3rem 5rem;
  align-items: center;
  gap: 2rem;
}
</style>
