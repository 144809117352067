<template>
  <div
    class="container"
    :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'"
  >
    <div class="card1-container">
      <v-form style="margin-top: 2rem" @submit.prevent="submitBooking">
        <div class="container-header">
          <div class="typing" :dir="isRTL ? 'rtl' : 'ltr'">
            <span class="typing-header">{{
              $t("message.enterYourDetails")
            }}</span>
            <span class="">{{ $t("message.enterBookingDetailsWarning") }}</span>
          </div>
        </div>
        <div class="inputs-show shadow">
          <div class="input-field-section-info">
            <div class="input-field-info">
              <div>
                {{ $t("message.fullname") }}
                <span style="color: red; font-size: large">*</span>
              </div>
              <v-text-field
                v-model="fullName"
                placeholder=" "
                density="compact"
                variant="outlined"
                :rules="required"
                @change="updateField('full_name', fullName)"
              ></v-text-field>
            </div>
            <div class="input-field-info">
              <div>
                {{ $t("message.phone") }}
                <span style="color: red; font-size: large">*</span>
              </div>
              <VueTelInput
                v-model="phone"
                class="py-0.5"
                @change="
                  (e) =>
                    updateField('phone', e.target.value?.replace(/\s|\+/g, ''))
                "
                mode="international"
              ></VueTelInput>
            </div>
          </div>
          <div class="input-field-section-info">
            <div class="input-field-info">
              <div>
                {{ $t("message.emailAddress") }}
                <span style="color: red; font-size: large">*</span>
              </div>
              <v-text-field
                v-model="email"
                placeholder=" "
                density="compact"
                variant="outlined"
                @change="updateField('email', email)"
                :rules="isValidEmail"
              ></v-text-field>
            </div>
            <div class="input-field-info">
              <div>
                {{ $t("message.country") }}
                <span style="color: red; font-size: large">*</span>
              </div>
              <v-autocomplete
                v-model="country"
                :items="countryNames"
                placeholder=" "
                density="compact"
                variant="outlined"
                :rules="required"
                @blur="updateField('country', country)"
              ></v-autocomplete>
            </div>
          </div>

          <!-- Hour and Minute Selectors for Arrival Time -->
          <div class="input-field-section-info">
            <div class="input-field-info">
              <div>
                {{ $t("message.arrivalDate") }}
                <span style="color: red; font-size: large">*</span>
              </div>
              <div style="display: flex; gap: 10px">
                <!-- Hour Selector -->
                <v-select
                  v-model="selectedHour"
                  :items="hours"
                  density="compact"
                  dense
                  variant="outlined"
                  :rtl="isRTL"
                  :rules="required"
                >
                  <template v-slot:prepend> {{ $t("message.hour") }} </template>
                </v-select>

                <!-- Minute Selector -->
                <v-select
                  density="compact"
                  v-model="selectedMinute"
                  :items="minutes"
                  dense
                  variant="outlined"
                  :rtl="isRTL"
                  :rules="required"
                >
                  <template v-slot:prepend>
                    {{ $t("message.minutes") }}
                  </template>
                </v-select>
              </div>
            </div>
          </div>
        </div>
        <div>
          <v-card class="mt-1 card-style">
            <div class="button-container">
              <button
                type="submit"
                class="text-white flex items-center gap-2 bg-[#003b7d] hover:bg-[#003b7d]/90 font-large rounded-full px-6 py-2 text-md text-center"
              >
                <div role="status" v-if="isSubmitting">
                  <svg
                    aria-hidden="true"
                    class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      class="fill-white/30"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      class="fill-white"
                    />
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
                {{ $t("message.completeBooking") }}
              </button>
            </div>
          </v-card>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script setup>
import { countryNames } from "country-region-data";
import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import router from "@/router";
import { VueTelInput } from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
import { toast } from "vue-sonner";

// Initialize store and route
const store = useStore();
const route = useRoute();
const { locale, t } = useI18n();

const isRTL = computed(() => locale !== "en");
// Form fields
const fullName = ref("");
const email = ref("");
const phone = ref("");
const country = ref("");
const isSubmitting = ref(false);

// Hour and Minute selectors
const selectedHour = ref(null); // Stores selected hour
const selectedMinute = ref(null); // Stores selected minute

// Hours and minutes list
const hours = Array.from({ length: 24 }, (_, i) =>
  i.toString().padStart(2, "0")
);
const minutes = Array.from({ length: 60 }, (_, i) =>
  i.toString().padStart(2, "0")
);

const required = [
  (value) => {
    if (value?.length >= 1) return true;

    return t("message.required_error_message");
  },
];
const isValidEmail = [
  (value) => {
    if (value?.length <= 1) {
      return t("message.required_error_message");
    }
    if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
      return true;
    }

    return t("message.invalid_email");
  },
];

// Computed property to combine selected hour and minute
const arrivalTime = computed(() => {
  if (selectedHour.value !== null && selectedMinute.value !== null) {
    return `${selectedHour.value}:${selectedMinute.value}`;
  }
  return null;
});

// Fetch reservation_id from the route params
const reservationId = route.params.id;

// Update form fields in Vuex
const updateField = (field, value) => {
  store.dispatch("updateBookingField", { field, value });
};

// Submit booking data to API, including combined arrival time
const submitBooking = async (form) => {
  const formState = await form;
  if (isSubmitting.value || !formState.valid) return;

  isSubmitting.value = true;
  try {
    // Update the arrival_time field with the combined hour and minute
    updateField("arrival_time", arrivalTime.value);

    await store.dispatch("confirmBooking", reservationId).then((res) => {
      // Assuming res.data.payment_url.href contains the URL you want to redirect to
      if (
        res &&
        res.data.data &&
        res.data.data.payment_url &&
        !store.getters.userInfo.userAuth.is_agent
      ) {
        // Redirect to the payment URL
        window.location.href = res.data.data.payment_url;
      } else {
        router.push("/hotel/booking-done");
      }
    });
  } catch (error) {
    toast.error("Error confirming booking");
    console.error("Error confirming booking:", error);
  } finally {
    isSubmitting.value = false;
  }
};
</script>

<style scoped>
.container-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #000;
  padding: 10px 10px;
  position: relative;
}
.container {
  margin: auto;
  font-family: "Cairo", sans-serif;
  width: 70%;
  margin-top: 1.5rem;
  direction: rtl;
  margin-bottom: 4rem;
}
.page-divider {
  display: flex;
  gap: 20px;
  width: 100%;
}
.page-divider .card1-container {
  width: 100%;
}
.inputs-show {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 2rem;
}
.input-field-section-info {
  display: flex;
  gap: 2rem;
  width: 100%;
}
.input-field-info {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  width: 45%;
}
.input-field-info-email {
  width: 100%;
}
.card-style {
  padding: 1.5rem;
}
.button-container {
  display: flex;
  justify-content: center;
  padding: 1.5rem 0;
}
.shadow {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}
@media (max-width: 768px) {
  .container {
    width: 90%;
  }
  .page-divider {
    flex-direction: column;
  }
  .input-field-section-info {
    flex-direction: column;
    gap: 1rem;
  }
  .input-field-info {
    width: 100%;
  }
}
</style>
