<template>
  <div>
    <div class="w-[90%] md:w-[70%] mx-auto flex flex-col md:grid md:grid-cols-[1fr_300px] gap-3 mt-2" v-if="showHotelOpptions" :dir="locale === 'en' ? 'ltr' : 'rtl'">
      <HotelImages :hotelsData="hotelsInfo" />
      <HotelImagesSideBar :hotelsData="hotelsInfo" />
    </div>
  </div>
</template>

<script>
import HotelImages from "./HotelUpperSectionShowComponents/HotelImages.vue";
import HotelImagesSideBar from "./HotelUpperSectionShowComponents/HotelImagesSideBar.vue";
import { useI18n } from "vue-i18n";

export default {
  /* eslint-disable */
  components: {
    HotelImages,
    HotelImagesSideBar,
  },
};
</script>
<script setup>
import { onMounted } from "vue";
import { ref, defineProps } from "vue";

// Define props directly within the script setup
const props = defineProps(["hotelsData"]);
const hotelsInfo = ref({});
const showHotelOpptions = ref(false);
const {locale} = useI18n();

// Define the showHotel function
const showHotel = async () => {
  try {
    hotelsInfo.value = props.hotelsData;
    showHotelOpptions.value = true;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

// Call the showHotel function when the component is mounted
onMounted(showHotel);
</script>

<style>
.Hotelupper-container2 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  color: #000;
  width: 70%;
  margin: auto;
  gap: 20px;
  margin-top: 10px;
}
@media (max-width: 1050px) {
  .Hotelupper-container2 {
    display: flex;
    flex-direction: column;
    width: 90%;
  }
}
</style>
