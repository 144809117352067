<template>
  <div v-if="showOffers || loading" :class="[{ rtl: locale === 'ar' || locale === 'kr' }, 'container']">
    <div class="container-header" :style="locale === 'ar' || locale === 'kr' ? '' : 'flex-direction: row-reverse;'">
      <div class="typing" :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'">
        <span class="typing-header">{{ slide[`${locale}_title`] }}</span>
        <span class="typing-paragraph">
          {{ slide[`${locale}_description`] }}
        </span>
      </div>
      <div class="slider-buttons">
        <div class="buttons-container" @click="moveCards('right')">
          <i class="fa-solid fa-arrow-right-long"></i>
        </div>
        <div class="buttons-container" @click="moveCards('left')">
          <i class="fa-solid fa-arrow-left-long"></i>
        </div>
      </div>
    </div>
    <div class="content" ref="cardContainer">
      <template v-if="loading">
        <div v-for="n in 6" :key="n" class="card-container skeleton-card-container">
          <div class="skeleton-image-container">
            <img src="@/assets/imgs/img.jpg" alt="" />
          </div>
          <div class="skeleton-card-text">
            <div class="skeleton-text" style="width: 60%"></div>
            <div class="skeleton-text" style="width: 80%"></div>
            <div class="skeleton-text" style="width: 40%"></div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="card-container shadow hover:bg-gray-100" v-for="(item, index) in sliders" :key="index" @click="search(item)">
          <div class="image-container">
            <img :src="item.image_path" alt="" />
          </div>
          <div class="card-text" :style="locale === 'ar' || locale === 'kr' ? '':' align-items: flex-end;'">
            <h1 :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'" v-if="item.ar_name">
              {{ locale === 'ar' || locale === 'kr'? item.ar_name : item.en_name }}
            </h1>
            <span :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'" class="time-text rounded-full px-2">{{ item.hotelsCount }} {{ $t('message.placesToStayIn') }}</span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, onBeforeMount, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from "vue-router";

const props = defineProps({
  slide: {
    type: Object,
    required: true,
  },
  loading: {
    type: Boolean,
    required: true,
  },
});
const sliders = ref([]);
const cardContainer = ref(null);
const showOffers = ref(false);
const { locale } = useI18n();
const router = useRouter();
const loading = ref(true);

const adult_no = ref(1);
const children_no = ref(0);
// const governorate = ref("");
const checkin = ref("");
const checkout = ref("");
const room_no = ref(1);

const search = (item) => {
  router.push({
    name: "Hotels",
    query: {
      // id: item.id,
      governorate: item.city.governorate_id,
      checkin: checkin.value,
      checkout: checkout.value,
      room_no: room_no.value,
      adult_no: adult_no.value,
      children_no: children_no.value,
    },
  });
};

onBeforeMount(async () => {
  try {
    sliders.value = props.slide.sliders.map((offer) => offer.child).filter((child) => child !== null);
    if (sliders.value.length) {
      showOffers.value = true;
    } else {
      showOffers.value = false;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    loading.value = false;
  }
});

const moveCards = (direction) => {
  const container = cardContainer.value;
  const cardWidth = container.offsetWidth;

  if (direction === 'left') {
    container.scrollTo({
      left: container.scrollLeft - cardWidth,
      behavior: 'smooth',
    });
  } else if (direction === 'right') {
    container.scrollTo({
      left: container.scrollLeft + cardWidth,
      behavior: 'smooth',
    });
  }
};

watch(
  () => props.sliders,
  (newVal) => {
    if (newVal && newVal[4] && newVal[4].sliders) {
      sliders.value = newVal[4].sliders.map((offer) => offer.child).filter((child) => child !== null);
      showOffers.value = sliders.value.length > 0;
    } else {
      showOffers.value = false;
    }
    loading.value = false;
  },
  { immediate: true }
);
</script>

<style scoped>
.container {
  margin: auto;
  font-family: 'Cairo', sans-serif;
  width: 70%;
  margin-bottom: 3rem;
  direction: rtl;
}
.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  overflow: hidden;
  gap: 20px;
  padding: 1rem;
}
.card-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 315px;
  border-radius: 10px;
}
.card-container:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: all 0.4s ease-in-out;
}
.card-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 16px;
  justify-content: space-between;
  padding: 5px 15px 15px 15px;
  gap: 8px;
  width: 100%;
  height: 100%;
}
.image-container {
  height: 160px;
  width: 315px;
}
.image-container img {
  width: 100%;
  border-radius: 10px;
  height: 100%;
  object-fit: cover;
  display: block;
}
.typing {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.typing-header {
  font-size: 26px;
  color: #ffb325;
  font-weight: bold;
}
.typing-paragraph {
  font-size: 14px;
  color: #000;
}
.time-text {
  color: #ffb325;
  font-size: 14px;
  background-color: #FAFAFA;
}
.show-more-button {
  margin-top: 30px;
}
.show-more-button button {
  background-color: #003b7d;
}
.show-more-button button:hover {
  background-color: #043961;
  transition: all 0.5s ease-in-out;
}
.container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.slider-buttons {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  gap: 10px;
}
.buttons-container {
  background-color: #003b7d;
  color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.buttons-container:hover {
  background-color: #043961;
  transform: scale(1.05);
  transition: all 0.4s ease-in-out;
}

/* Skeleton Loader Styles */
.skeleton-card-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 315px;
  border-radius: 10px;
}
.skeleton-image-container {
  height: 160px;
  width: 315px;
  background-color: #e0e0e0;
  border-radius: 10px;
}
.skeleton-image-container img {
  width: 100%;
  border-radius: 10px;
  height: 100%;
  object-fit: cover;
  display: block;
}
.skeleton-card-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 16px;
  padding: 10px 15px 15px 15px;
  gap: 8px;
  width: 100%;
  height: 100%;
}
.skeleton-text {
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 4px;
}
</style>
