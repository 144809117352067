import { createStore } from 'vuex';
import users from "./modules/Users";
import auth from "./modules/auth.js";

export default createStore({
  state: {
    editStatus: false,
    sideBarStatus: true,
    loadingData: false,
    getLoader: false,
    postLoader: false,
  },
  getters: {
    editStatus: state => state.editStatus,
    sideBarStatus: state => state.sideBarStatus,
    getLoader: state => state.getLoader,
    postLoader: state => state.postLoader,
  },
  mutations: {
    toggleEditStatus(state) {
      state.editStatus = !state.editStatus;
    },
    toggleSideBarStatus(state) {
      state.sideBarStatus = !state.sideBarStatus;
    },
  },
  actions: {
    // Define your actions if needed
  },
  modules: {
    auth,
    users,
  },
});
