<template>
  <div
    :class="[{ rtl: locale === 'ar' || locale === 'kr' }, 'w-[90%] md:w-[70%] mx-auto']"
    :dir="locale === 'en' ? 'ltr' : 'rtl'"
  >
    <h2 class="typing-header mb-1">
      {{t('message.bookNow')}}
    </h2>
    <p class="text-sm mb-2">
      {{t('message.bookNowDescription')}}
    </p>
    <div class="bg-white rounded-lg">
      <div
        :dir="locale === 'ar' || locale === 'kr' ? 'ltr' : 'rtl'"
        class="input-fields px-10 py-6"
      >
        <div
          :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'"
          class="left-field"
          style="margin-bottom: 1.45rem"
        >
          <div class="pb-1">
            <v-icon
              class="mx-1"
              size="large"
              icon="mdi-calendar-blank"
            ></v-icon>
            <span class="text-sm">{{ $t("message.durationOfStay") }}</span>
          </div>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="350px"
          >
            <template v-slot:activator="{ props }">
              <VueDatePicker
                :dir="isRTL ? 'rtl' : 'ltr'"
                v-bind="props"
                :value="dateToShow"
                :select-text="$t('actions.done')"
                :cancel-text="$t('actions.deselect')"
                :enable-time-picker="false"
                v-model="dateRange"
                range
                multi-calendars
                class="custom-date-picker"
                :min-date="new Date()"
                :teleport="true"
                @blur="changeDate()"
              >
                <template #action-preview="{ value }">
                  {{ getDate(value) }}
                </template>
              </VueDatePicker>
            </template>
          </v-menu>
        </div>
        <div class="center-field">
          <div
            :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'"
            class="pb-1"
          >
            <v-icon
              class="mx-1"
              size="large"
              icon="mdi-account-multiple-outline"
            ></v-icon>
            <span class="text-sm">{{ $t("message.numberOfPeople") }}</span>
          </div>
          <v-locale-provider :rtl="isRTL">
            <v-text-field
              density="compact"
              variant="outlined"
              v-model="people_no_text_field"
              @click="peopleNoClick()"
            ></v-text-field>
          </v-locale-provider>
          <v-card ref="peopleCard" class="people-card" v-if="peopleModel">
            <div
              style="
                display: flex;
                align-items: baseline;
                justify-content: space-between;
                border-bottom: 1px solid #eee;
                margin: 1rem;
                padding-bottom: 1rem;
              "
            >
              <div style="font-size: 16px; color: #ffb325">
                {{ $t("message.numberOfPeople") }}
              </div>
              <div @click="closeNoModel()" style="cursor: pointer">
                <button
                  type="button"
                  class="text-white bg-[#003b7d] hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-6 py-3 text-center"
                  @click="changeDate()"
                >
                  {{ $t("actions.done") }}
                </button>
              </div>
            </div>
            <div class="input-number">
              <div>{{ $t("message.numberOfRooms") }}</div>
              <div style="margin-top: 1rem; z-index: 1000">
                <v-text-field
                  variant="outlined"
                  density="compact"
                  v-model="room_no"
                >
                  <template v-slot:append>
                    <v-btn
                      color="#fff"
                      density="compact"
                      icon
                      @click="addOne('room_no')"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:prepend>
                    <v-btn
                      color="#fff"
                      density="compact"
                      icon
                      @click="minusOne('room_no')"
                    >
                      <v-icon> mdi-minus </v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </div>
            </div>
            <div class="input-number">
              <div>{{ $t("message.numberOfAdults") }}</div>
              <div style="margin-top: 1rem">
                <v-text-field
                  style="padding: 0rem; margin: auto"
                  variant="outlined"
                  density="compact"
                  v-model="adult_no"
                >
                  <template v-slot:append>
                    <v-btn
                      color="#fff"
                      density="compact"
                      icon
                      @click="addOne('adult_no')"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:prepend>
                    <v-btn
                      color="#fff"
                      density="compact"
                      icon
                      @click="minusOne('adult_no')"
                    >
                      <v-icon> mdi-minus </v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </div>
            </div>
            <div class="input-number">
              <div>{{ $t("message.numberOfChildren") }}</div>
              <div style="margin-top: 1rem">
                <v-text-field
                  variant="outlined"
                  density="compact"
                  v-model="children_no"
                >
                  <template v-slot:append>
                    <v-btn
                      color="#fff"
                      density="compact"
                      icon
                      @click="addOne('children_no')"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:prepend>
                    <v-btn
                      color="#fff"
                      density="compact"
                      icon
                      @click="minusOne('children_no')"
                    >
                      <v-icon> mdi-minus </v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </div>
            </div>
            <div v-if="children_no">
              <div
                v-for="(item, index) in children_no"
                :key="index"
                class="input-number"
              >
                <div>{{ $t("message.childAge", { index: index + 1 }) }}</div>
                <div style="margin-top: 1rem">
                  <v-locale-provider :rtl="isRTL">
                    <v-select
                      density="compact"
                      :rtl="isRTL"
                      :items="[
                        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
                        17,
                      ]"
                      item-title="ar_name"
                      item-value="id"
                      v-model="child_age[index]"
                      variant="outlined"
                    >
                      <template v-slot:prepend>
                        {{ $t("message.year") }}
                      </template>
                    </v-select>
                  </v-locale-provider>
                </div>
              </div>
            </div>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  components: { VueDatePicker },
  name: "SearchBox",
  setup() {
    const { t, locale } = useI18n();
    const isRTL = locale.value === "ar";

    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const dateRange = ref([]);
    const dateRangeFull = ref([]);
    const room_no = ref(1);
    const menu = ref(false);
    const adult_no = ref(1);
    const children_no = ref(0);
    const peopleModel = ref("");
    const people_no_text_field = ref("");
    const dateToShow = ref("");
    const governorate = ref("");
    const checkin = ref("");
    const checkout = ref("");
    const todayDate = ref("");
    const governorates = ref([]);
    const searchFieldInputs = ref([]);
    const tomorrowDate = ref("");
    const child_age = ref([]);
    const searchField = ref("");

    const date = ref(new Date());

    const getDate = () => {
      return "";
    };
    checkin.value = route.query.checkin ?? todayDate.value;
    checkout.value = route.query.checkout ?? tomorrowDate.value;

    if (route.query.checkin && route.query.checkout) {
      dateRange.value = [];
      const currentDateQuery = new Date(route.query.checkin);
      const endDateeQuery = new Date(route.query.checkout);
      dateRange.value.push(new Date(currentDateQuery));
      dateRange.value.push(new Date(endDateeQuery));
      dateToShow.value = [checkin.value, checkout.value];
    } else {
      dateRange.value = [];
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");
      checkin.value = `${year}-${month}-${day}`;
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      const year2 = tomorrow.getFullYear();
      const month2 = (tomorrow.getMonth() + 1).toString().padStart(2, "0");
      const day2 = tomorrow.getDate().toString().padStart(2, "0");
      checkout.value = `${year2}-${month2}-${day2}`;
      dateRange.value.push(new Date(checkout.value));
      dateRange.value.push(new Date(checkin.value));
      dateToShow.value = [checkin.value, checkout.value];
    }
    if (route.query.room_no) {
      room_no.value = route.query.room_no;
    }
    if (route.query.adult_no) {
      adult_no.value = route.query.adult_no;
    }
    if (route.query.children_no) {
      children_no.value = route.query.children_no;
    }
    if (
      route.query.room_no ||
      route.query.adult_no ||
      route.query.children_no
    ) {
      people_no_text_field.value = `${room_no.value} ${t("message.room")} - ${
        adult_no.value
      } ${t("message.adult")} - ${children_no.value} ${t("message.child")}`;
    }

    // Add this debounce function at the top of the setup function
    const debounce = (func, delay) => {
      let timeout;
      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), delay);
      };
    };

    const showSearch = async () => {
      if (searchField.value?.type == "hotel") {
        router.push({
          name: "Hotel",
          query: {
            id: searchField.value?.id,
            governorate: governorate.value,
            checkin: checkin.value,
            checkout: checkout.value,
            room_no: room_no.value == 0 ? 1 : room_no.value,
            adult_no: adult_no.value == 0 ? 1 : adult_no.value,
            children_no: children_no.value,
          },
        });
      } else {
        governorate.value = searchField.value?.id;
      }
    };

    const getSearch = (search) => {
      const searchParams = {
        search: search ? search : "",
        id: route.query.id,
        governorate: governorate.value??(route.query.governorate)??"",
        checkin: checkin.value ? checkin.value : todayDate.value,
        checkout: checkout.value ? checkout.value : tomorrowDate.value,
        room_no: room_no.value ? room_no.value : 1,
        adult_no: adult_no.value ? adult_no.value : 1,
        children_no: children_no.value,
        currency: store.getters.currency_selected,
      };
      router.push({
        query: {
          ...(route.query || {}),
          ...searchParams,
        },
      });
    };

    // Create a debounced version of getSearch with a short delay (200ms)
    const debouncedGetSearch = debounce(getSearch, 300);

    const getGovernorate = async () => {
      try {
        if (route.query.governorate || !governorates.value.length) {
          await store.dispatch("getGovernorates");
        }
        governorates.value = store.getters.governorate;
        for (let item in governorates.value) {
          if (route.query.governorate == governorates.value[item].id) {
            searchField.value = governorates.value[item].en_name;
          }
        }
      } catch (error) {
        console.error("Error fetching governorates:", error);
      }
    };

    onMounted(() => {
      getSearch();
    });

    const changeDate = async () => {
      setTimeout(() => {
        formatDates();
        router.push({
          query: {
            id: route.query.id,
            governorate: governorate.value ?? route.query.governorate ?? "",
            checkin: checkin.value ?? route.query.checkin ?? todayDate.value,
            checkout:
              checkout.value ?? route.query.checkout ?? tomorrowDate.value,
            room_no: room_no.value ?? route.query.room_no ?? 1,
            adult_no: adult_no.value ?? route.query.adult_no ?? 1,
            children_no: children_no.value ?? route.query.children_no ?? 0,
          },
        });
      }, 500);
    };

    const minusOne = (field) => {
      if (field === "room_no" && room_no.value > 0) {
        room_no.value--;
      } else if (field === "adult_no" && adult_no.value > 0) {
        adult_no.value--;
      } else if (field === "children_no" && children_no.value !== 0) {
        children_no.value--;
        child_age.value.pop();
      }
      router.push({
        query: {
          id: route.query.id,
          governorate: governorate.value ?? route.query.governorate ?? "",
          checkin: checkin.value ?? route.query.checkin ?? todayDate.value,
          checkout:
            checkout.value ?? route.query.checkout ?? tomorrowDate.value,
          room_no: room_no.value ?? route.query.room_no ?? 1,
          adult_no: adult_no.value ?? route.query.adult_no ?? 1,
          children_no: children_no.value ?? route.query.children_no ?? 0,
        },
      });
      setupPeopleNoField();
    };
    const addOne = (field) => {
      if (field === "room_no") {
        room_no.value++;
      } else if (field === "adult_no") {
        adult_no.value++;
      } else if (field === "children_no") {
        child_age.value.push(0);
        children_no.value++;
      }
      router.push({
        query: {
          id: route.query.id,
          governorate: governorate.value ?? route.query.governorate ?? "",
          checkin: checkin.value ?? route.query.checkin ?? todayDate.value,
          checkout:
            checkout.value ?? route.query.checkout ?? tomorrowDate.value,
          room_no: room_no.value ?? route.query.room_no ?? 1,
          adult_no: adult_no.value ?? route.query.adult_no ?? 1,
          children_no: children_no.value ?? route.query.children_no ?? 0,
        },
      });
      setupPeopleNoField();
    };

    const setupPeopleNoField = () => {
      people_no_text_field.value = `${room_no.value} ${t("message.room")} - ${
        adult_no.value
      } ${t("message.adult")} - ${children_no.value} ${t("message.child")}`;
    };

    const closeNoModel = () => {
      peopleModel.value = false;
    };

    const peopleNoClick = () => {
      peopleModel.value = true;
    };

    const formatDates = () => {
      console.log(dateRange.value);
      if (!dateRange.value) {
        dateRange.value = [];
      }
      if (dateRange.value.length === 2) {
        let date = new Date(dateRange.value[0]);
        let year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, "0");
        let day = date.getDate().toString().padStart(2, "0");
        checkin.value = `${year}-${month}-${day}`;

        date = new Date(dateRange.value[1]);
        year = date.getFullYear();
        month = (date.getMonth() + 1).toString().padStart(2, "0");
        day = date.getDate().toString().padStart(2, "0");
        checkout.value = `${year}-${month}-${day}`;

        dateRangeFull.value = dateRange.value;
        dateToShow.value = `${checkin.value} --- ${checkout.value}`;
      }
      if (
        dateRangeFull.value != dateRange.value &&
        dateRange.value.length != 0 &&
        dateRange.value.length != 1
      ) {
        dateRange.value = [];
        dateToShow.value = `${checkin.value} --- ${checkout.value}`;
      }
    };

    watch(dateRange, () => {
      console.log("dateRange", dateRange.value);
      formatDates();
    });

    const getHotels = async () => {
      try {
        await store.dispatch("getHotels", {
          take: 9,
          page: 1,
          governorate: governorate.value,
          checkin: checkin.value ?? route.query.checkin ?? todayDate.value,
          checkout:
            checkout.value ?? route.query.checkout ?? tomorrowDate.value,
          room_no: room_no.value ?? route.query.room_no ?? 1,
          adult_no: adult_no.value ?? route.query.adult_no ?? 1,
          children_no: children_no.value ?? route.query.children_no ?? 0,
          currency: store.getters.currency_selected,
        });
        // window.location.reload();
      } catch (error) {
        console.error("Error fetching hotels:", error);
      }
    };

    const search = debounce(() => {
      router.push({
        query: {
          id: route.query.id,
          governorate: governorate.value,
          checkin: checkin.value ?? route.query.checkin ?? todayDate.value,
          checkout:
            checkout.value ?? route.query.checkout ?? tomorrowDate.value,
          room_no: room_no.value ?? route.query.room_no ?? 1,
          adult_no: adult_no.value ?? route.query.adult_no ?? 1,
          children_no: children_no.value ?? route.query.children_no ?? 0,
        },
      });
    }, 500);
    // Computed property to handle custom filtering
    const filteredItems = computed(() => {
      return searchFieldInputs.value;
    });
    // Watcher for searchFieldInputs to update filteredItems
    watch(searchFieldInputs, () => {
      filteredItems.value = searchFieldInputs.value;
    });

    return {
      dateRange,
      room_no,
      adult_no,
      children_no,
      peopleModel,
      people_no_text_field,
      dateToShow,
      addOne,
      changeDate,
      getHotels,
      minusOne,
      setupPeopleNoField,
      closeNoModel,
      peopleNoClick,
      search,
      getSearch: debouncedGetSearch, // Use debounced function here
      menu,
      governorates,
      governorate,
      checkin,
      checkout,
      todayDate,
      getDate,
      date,
      child_age,
      searchField,
      showSearch,
      getGovernorate,
      searchFieldInputs,
      locale,
      isRTL,
      t,
      filteredItems,
    };
  },
};
</script>

<style scoped>
.content {
  z-index: 20;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: auto;
}
.input-fields {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin: auto;
}
.right-field,
.center-field,
.left-field {
  z-index: 200;
  flex: 1;
}
.rtl-select .v-select__slot .v-select__placeholder {
  text-align: right !important;
  direction: rtl !important;
}
.btn-div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  align-content: center;
  padding-bottom: 3rem;
  padding-right: 2rem;
}
.btn-div-rtl {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-content: center;
  padding-bottom: 3rem;
  padding-left: 2rem;
}
button {
  background-color: #003b7d;
}
@media (max-width: 1000px) {
  .input-fields {
    display: block;
  }
  .right-field,
  .center-field,
  .left-field {
    z-index: 200;
    flex: 100%;
  }
}
@media (max-width: 1280px) {
  .input-fields {
    display: block;
  }
  .right-field,
  .center-field,
  .left-field {
    z-index: 200;
    flex: 100%;
  }
  .search-text {
    display: block;
    margin-top: 1rem;
  }
}
.center-field {
  position: relative;
}
.people-card {
  position: absolute;
  width: 100%;
  top: 100px;
}

@media (max-width: 1200px) {
  .people-card {
    position: absolute;
    width: 100%;
  }
}
.input-number {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: baseline;
  gap: 1rem;
  justify-content: space-around;
}
@media (max-width: 1350px) {
  .input-number {
    font-size: 13px;
  }
}

/* Dark Mode */
.dp__theme_dark {
  --dp-border-color: #aaaeb7;
  --dp-menu-border-color: #aaaeb7;
  --dp-border-color-hover: #000;
}

/* Light Mode */
.dp__theme_light {
  --dp-border-color: #aaaeb7;
  --dp-menu-border-color: #aaaeb7;
  --dp-border-color-hover: #000;
}

/* Custom class to apply the theme */
.custom-date-picker {
  --dp-font-size: 1rem;
  --dp-preview-font-size: 0.8rem;
  --dp-time-font-size: 0.8rem;
  --dp-action-buttons-padding: 12px 15px;
}
</style>
