<template>
  <div :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'" class="container">
    <div class="container-header">
      <div class="typing">
        <v-locale-provider :rtl="locale === 'ar' || locale === 'kr'">
          <v-select
            density="compact"
            :label="$t('message.filterByProvince')"
            :items="mappedGovernorates"
            item-value="id"
            item-title="name"
            variant="outlined"
            v-model="selectedGovernorate"
            @change="filterOffers"
            :loading="loadingGovernorates"
            :disabled="loadingGovernorates"
          ></v-select>
        </v-locale-provider>
      </div>
    </div>
    <div class="content">
      <div v-if="loadingOffers" class="loading-container">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <div v-else-if="!offers.length" class="show-list-container2">
        <div class="hotel-not-found">
          <div>
            <v-icon class="text-orange" size="80" icon="mdi-alert-circle"></v-icon>
          </div>
          <div style="font-size: 28px; color: green">{{ $t("message.noOffers") }}</div>
          <div>{{ $t("message.tryDifferentSearch") }}</div>
        </div>
      </div>
      <div
        v-else
        class="card-container shadow hover:bg-gray-100"
        v-for="(item, index) in offers"
        :key="index"
        @click="hotelClick(item)"
      >
        <div class="image-container">
          <img :src="item.image_path" alt="" />
        </div>
        <div class="card-text">
          <h1 style="font-size: 16px">
            {{ locale === "ar" ? item.ar_title : item.en_title }}
          </h1>
          <h1>
            {{ locale === "ar" ? item.ar_description.slice(0,220) : item.en_description.slice(0,220) }}..
          </h1>
          <span
            >{{ $t("message.offerEnds") }}:
            <span class="time-text">{{ item.end_date }}</span></span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

export default {
  name: "offersMainList",
  setup() {
    const store = useStore();
    const router = useRouter();
    const { locale, t } = useI18n();
    const offers = ref([]);
    const governorate = computed(() => store.getters.governorate);
    const selectedGovernorate = ref("");
    const take = 15; // Number of items to take per request
    const page = 1; // Page number
    const loadingGovernorates = ref(true); // Loading state for governorates
    const loadingOffers = ref(false); // Loading state for offers
    const adult_no = ref(1);
    const children_no = ref(0);
    const governorateRef = ref("");
    const checkin = ref("");
    const checkout = ref("");
    const room_no = ref(1);

    const getOffers = async (governorateId = "") => {
      loadingOffers.value = true;
      try {
        await store.dispatch("getOffer", { take, page, governorate_id: governorateId });
        offers.value = store.getters.offers.data;
      } catch (error) {
        console.error("Error fetching Offers:", error);
      } finally {
        loadingOffers.value = false;
      }
    };

    const getGovernorates = async () => {
      try {
        await store.dispatch("getGovernorates");
      } catch (error) {
        console.error("Error fetching Governorates:", error);
      } finally {
        loadingGovernorates.value = false; // Set loading to false once the request is complete
      }
    };

    const filterOffers = async () => {
      await getOffers(selectedGovernorate.value);
    };

    const hotelClick = (item) => {
      router.push({
        name: "Hotels",
        query: {
          governorate: item.governorate_id,
          checkin: checkin.value,
          checkout: checkout.value,
          room_no: room_no.value,
          adult_no: adult_no.value,
          children_no: children_no.value,
        },
      });
    };

    onMounted(() => {
      getGovernorates();
      getOffers();
    });

    const mappedGovernorates = computed(() => {
      const mapped = governorate.value.map((gov) => ({
        id: gov.id,
        name: locale.value === "ar" ? gov.ar_name : gov.en_name,
      }));
      return mapped;
    });

    // Watch for changes in selectedGovernorate and refetch offers
    watch(selectedGovernorate, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        filterOffers();
      }
    });

    return {
      offers,
      mappedGovernorates,
      selectedGovernorate,
      locale,
      t,
      filterOffers,
      loadingGovernorates,
      loadingOffers,
      hotelClick,
      adult_no,
      children_no,
      governorateRef,
      checkin,
      checkout,
      room_no,
    };
  },
};
</script>

<style scoped>
.container {
  margin: auto;
  font-family: "Cairo", sans-serif;
  width: 70%;
}
.content {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 25px;
}
.card-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 420px;
  border-radius: 10px;
}
.card-container:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: all 0.4s ease-in-out;
}
.card-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  font-size: 12px;
  margin: 10px;
  width: calc(100% - 170px);
  height: 100%;
}
.image-container {
  height: 180px;
  width: 160px;
}
.image-container img {
  width: 100%;
  border-radius: 10px;
  height: 100%;
  object-fit: cover;
  display: block;
}
.typing {
  margin: 3rem 1rem 0rem 1rem;
  width: 100%;
  flex-grow: 1;
}
.show-list-container2 {
  width: 100%;
}
.hotel-not-found {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem 3rem;
  align-items: center;
  gap: 2rem;
}
.time-text {
  color: #ffb325;
}
.show-more-button {
  margin-top: 30px;
}
.show-more-button button {
  background-color: #003b7d;
}
.show-more-button button:hover {
  background-color: #043961;
  transition: all 0.5s ease-in-out;
}
.container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
}
.slider-buttons {
  display: flex;
  align-items: center;
  margin-top: 0;
}
button:hover {
  opacity: 0.8;
  transition: all 0.3s ease-in-out;
}

/* Responsive Design */
@media (max-width: 1050px) {
  .container {
    width: 95%;
  }
  .card-container {
    flex-direction: column;
    height: auto;
    width: 100%;
  }
  .image-container {
    width: 100%;
    height: 200px;
  }
  .card-text {
    width: 100%;
    margin-top: 10px;
    padding: 1rem;
  }
}

@media (max-width: 1250px) {
  .container {
    width: 100%;
  }
}

@media (max-width: 1050px) {
  .container {
    width: 85%;
  }
}
.loading-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 300px;
  align-items: center;
}
</style>
