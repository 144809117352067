<template>
  <div v-if="showOffers || loading" :class="[{ rtl: locale === 'ar' || locale === 'kr' }, 'container']">
    <div
      class="container-header"
      :style="locale === 'ar' || locale === 'kr' ? '' : 'flex-direction: row-reverse;'"
    >
      <div class="typing" :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'">
        <span class="typing-header">{{ slide[`${locale}_title`] }}</span>
        <span class="typing-paragraph">{{ slide[`${locale}_description`] }}</span>
      </div>
      <div class="slider-buttons">
        <div class="buttons-container" @click="moveCards('right')">
          <i class="fa-solid fa-arrow-right-long"></i>
        </div>
        <div class="buttons-container" @click="moveCards('left')">
          <i class="fa-solid fa-arrow-left-long"></i>
        </div>
      </div>
    </div>
    <div
      class="content"
      ref="cardContainer"
      :style="locale === 'ar' || locale === 'kr' ? '' : 'flex-direction: row-reverse;'"
    >
      <template v-if="loading">
        <div v-for="n in 4" :key="n" class="skeleton-card-container">
          <div class="skeleton-image-container"></div>
          <div class="skeleton-card-text">
            <div class="skeleton-text" style="width: 80%"></div>
            <div class="skeleton-subtext" style="width: 40%"></div>
          </div>
        </div>
      </template>
      <template v-else>
        <div
          class="card-container shadow hover:bg-gray-100"
          v-for="(item, index) in sliders"
          :key="index"
          :style="locale === 'ar' || locale === 'kr' ? '' : 'flex-direction: row-reverse;'"
          @click="hotelClick(item)"
        >
          <div class="image-container">
            <span class="icon-container">
              <v-icon class="ml-2" size="large" icon="mdi-sale-outline"></v-icon>
            </span>
            <span class="percentage-container"> {{ item.percentage }}% </span>
          </div>
          <div class="card-text">
            <h1 :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'">
              <span v-if="item.ar_description">
                {{
                locale === "ar"
                  ? item.ar_description.slice(0, 255)
                  : item.en_description.slice(0, 255)
              }}...
              </span>
              <span v-if="!item.ar_description">
                {{
                locale === "ar"
                  ? 'لايوجد وصف'
                  : 'No Description available'
              }}...
              </span>
            </h1>
            <span :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'">
              <span class="time-text"
                >{{ $t("message.discountPercentage") }}: {{ item.percentage }}%</span
              >
            </span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, watch } from "vue";
// import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import {  useRouter } from "vue-router";

const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
  slide: {
    type: Object,
    required: true,
  },
  loading: {
    type: Boolean,
    required: true,
  },
});

// const store = useStore();
const { locale } = useI18n();
const cardContainer = ref(null);
const showOffers = ref(false);
const sliders = ref(props.data);
const router = useRouter();

// Watch for changes in sliders prop to handle loading and data population
watch(
  () => props.data,
  (newVal) => {
    sliders.value = newVal;
    showOffers.value = sliders.value.length > 0;
  },
  { immediate: true }
);
const hotelClick = (item) => {
  router.push({
    name: "Hotel",
    query: {
      id: item.hotel_id,
      // governorate: governorate,
      // checkin: checkin,
      // checkout: checkout,
      // room_no: room_no,
      // adult_no: adult_no,
      // children_no: children_no,
    },
  });
};

// Define the method to move cards
const moveCards = (direction) => {
  const container = cardContainer.value;
  const cardWidth = container.offsetWidth;

  if (direction === "left") {
    container.scrollTo({
      left: container.scrollLeft - cardWidth,
      behavior: "smooth",
    });
  } else if (direction === "right") {
    container.scrollTo({
      left: container.scrollLeft + cardWidth,
      behavior: "smooth",
    });
  }
};
</script>

<script>
export default {
  name: "DiscountsComponent",
};
</script>

<style scoped>
.container {
  margin: auto;
  font-family: "Cairo", sans-serif;
  width: 70%;
  direction: rtl;
  margin-bottom: 3rem;
}
.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  overflow: hidden;
  gap: 20px;
  padding: 1rem 0rem;
}
.card-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 175px;
  width: 420px;
  border-radius: 20px;
}
.card-container:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: all 0.4s ease-in-out;
}
.card-text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 20px;
  font-size: 12px;
  margin: 10px;
  width: 245px;
  height: 100%;
}
.image-container {
  background: #003b7d;
  border-radius: 10px;
  height: 175px;
  width: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
.image-container img {
  width: 100%;
  border-radius: 10px;
  height: 100%;
  object-fit: cover;
  display: block;
}
.typing {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.typing-header {
  font-size: 26px;
  color: #ffb325;
  font-weight: bold;
}
.typing-paragraph {
  font-size: 14px;
  color: #000;
}
.time-text {
  color: #ffb325;
}
.show-more-button {
  margin-top: 30px;
}
.show-more-button button {
  background-color: #003b7d;
}
.show-more-button button:hover {
  background-color: #043961;
  transition: all 0.5s ease-in-out;
}
.container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.slider-buttons {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  gap: 10px;
}
.buttons-container {
  background-color: #003b7d;
  color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.buttons-container:hover {
  background-color: #043961;
  transform: scale(1.05);
  transition: all 0.4s ease-in-out;
}
.percentage-container,
.icon-container {
  color: #fff;
  font-size: 35px;
  font-weight: 100;
}
/* Skeleton Loader Styles */
.skeleton-card-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 175px;
  width: 420px;
  border-radius: 20px;
  background-color: #fff;
}
.skeleton-image-container {
  background: #e0e0e0;
  border-radius: 10px;
  height: 175px;
  width: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
.skeleton-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  border-radius: 10px;
}
.skeleton-card-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  font-size: 12px;
  margin: 10px;
  width: 245px;
  height: 100%;
}
.skeleton-text {
  height: 100px;
  background-color: #e0e0e0;
  border-radius: 4px;
}
.skeleton-subtext {
  height: 15px;
  background-color: #e0e0e0;
  border-radius: 4px;
}
@keyframes pulse {
  0% {
    background-color: #f0f0f0;
  }
  50% {
    background-color: #e0e0e0;
  }
  100% {
    background-color: #f0f0f0;
  }
}
</style>
