<template>
  <div
    class="w-[90%] md:w-[70%] mx-auto bg-white rounded-lg mt-2 shadow"
    :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'"
  >
    <div class="container-header">
      <div class="right-side-header rating-count">
        <div>
          <span style="font-size: 24px" class="font-bold">{{ hotelsInfo.title }}</span>
        </div>
        <span>
          {{ hotelsInfo.address || 'No Address' }}
        </span>
      </div>
      <div class="left-side-header">
        <div>
          <span class="rating-count rounded-full text-center start-count">
            <v-icon class="text-orange" size="14" icon="mdi-star"></v-icon
            ><span>4</span>
          </span>
          <span class="start-count mx-2 rounded-full">
            <v-icon
              class="text-orange"
              size="14"
              icon="mdi-star"
              v-for="(item, index) in hotelsInfo.stars"
              :key="index"
            ></v-icon>
            <span class="rating-count">{{ hotelsInfo.stars }}/5</span>
          </span>
        </div>
      </div>
    </div>
    <HotelItemsServices class="mb-3 px-4" :hotelsInfo="hotelsInfo" />
    <div class="description-container">
      <div v-if="hotelsInfo.ar_description">
        {{
          locale === "ar" || locale === "kr"
            ? hotelsInfo.ar_description
            : hotelsInfo.en_description
        }}
      </div>
      <div v-if="!hotelsInfo.ar_description">
        {{ $t("message.noDescription") }}
      </div>
    </div>
  </div>
</template>

<script>
import HotelItemsServices from "@/components/ShowHotelComponents/HotelItemsServices.vue";

export default {
  name: "HotelHeader",
  components: {
    HotelItemsServices
  },
  data() {
    return {};
  },
};
</script>

<script setup>
import { onMounted } from "vue";
import { ref, defineProps } from "vue";
import { useI18n } from "vue-i18n";

const { locale } = useI18n();
const props = defineProps(["hotelsData"]);
const hotelsInfo = ref({});
const showHotelOpptions = ref(false);

const showHotel = async () => {
  try {
    hotelsInfo.value = props.hotelsData;
    showHotelOpptions.value = true;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

onMounted(showHotel);
</script>

<style scoped>
.container-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #000;
  padding: 15px 15px;
  position: relative;
}
.services-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 1rem;
  padding: 1rem;
}
.service-item {
  background-color: #fafafa;
  font-size: 16px;
}
.description-container {
  margin-top: 1rem;
  padding: 2rem;
}
.rating-count {
  font-size: 12px;
  margin-right: 3px;
}
.start-count {
  background-color: #fafafa;
  padding: 2px 8px;
  align-items: center;
  gap: 1px;
}


@media (min-width: 768px) and (max-width: 1023px) {
  .left-side-header {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
</style>
