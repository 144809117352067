<script setup>
import { computed, onMounted, ref } from "vue";

// eslint-disable-next-line no-undef
const props = defineProps({
  modelValue: {
    type: [String, File],
    default: null,
  },
});
// eslint-disable-next-line no-undef
const emit = defineEmits(["update:modelValue"]);

const inlineImage = ref("");
const isInvalid = ref(false);

const inlineValue = computed({
  get: () => props.modelValue || null,
  set: (val) => {
    emit("update:modelValue", val);
  },
});

/**
 * Returns file details if file is selected
 * @description File details
 * @type {ComputedRef<null | { name: string; size: number; type: string }>}
 * @see https://developer.mozilla.org/en-US/docs/Web/API/File
 */
const fileDetails = computed(() => {
  if (inlineValue.value && inlineValue.value instanceof File) {
    return {
      name: inlineValue.value.name,
      size: inlineValue.value.size,
      type: inlineValue.value.type,
    };
  }
  return null;
});

/**
 * Get base64 of file
 * @param file
 */
const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const checkIfFileIsImage = (file) => {
  const acceptedImageTypes = ["image/gif", "image/jpeg", "image/png"];
  return file && acceptedImageTypes.includes(file.type);
};

const setFile = (file) => {
  if (checkIfFileIsImage(file)) {
    getBase64(file).then((data) => {
      inlineImage.value = data;
    });
    inlineValue.value = file;
  } else {
    isInvalid.value = true;
    setTimeout(() => {
      isInvalid.value = false;
    }, 1000);
  }
};

const onSelectFileHandler = (e) => {
  const files = e.target.files;
  if (files) setFile(files[0]);
};

const onDropHandler = (e) => {
  const files = e.dataTransfer?.files;
  if (files) setFile(files[0]);
};

onMounted(() => {
  if (typeof inlineValue.value === "string") {
    inlineImage.value = inlineValue.value;
    inlineValue.value = null;
  }
});
</script>
<template>
  <div class="w-full">
    <div class="flex w-full items-center justify-center">
      <label
        for="dropzone-file"
        class="flex h-[150px] w-full cursor-pointer flex-col items-center justify-center rounded-lg border bg-gray-50 hover:bg-gray-100"
        :class="{
          'animate-shake bg-red-100': isInvalid,
        }"
        @drop.prevent="onDropHandler"
      >
        <div
          class="flex flex-col items-center justify-center pb-6 pt-5 text-center"
        >
          <svg
            aria-hidden="true"
            class="mb-3 h-10 w-10 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
            ></path>
          </svg>
          <p
            class="mb-2 text-sm font-semibold text-gray-500"
          >
            {{ $t("message.dragDrop") }}
          </p>
          <p class="text-xs text-gray-500">
            PNG, JPG or GIF
          </p>
        </div>
        <input
          id="dropzone-file"
          type="file"
          class="hidden"
          @change="onSelectFileHandler"
        />
      </label>
    </div>
    <div
      v-if="fileDetails || inlineImage"
      class="flex w-full items-center rounded border p-4"
    >
      <img :src="inlineImage" :alt="fileDetails?.name" width="50" />
      <span class="mx-2 flex-1">
        {{ $t("message.fileName") }}: {{ fileDetails?.name || "Unknown" }}
      </span>
      <span>
        {{ $t("message.fileSize") }}:
        {{ ((fileDetails?.size || 0) / (1024 * 1024)).toFixed(2) }} MB
      </span>
    </div>
  </div>
</template>
