<template>
  <div class="container">
    <div class="container-header">
      <div class="typing" dir="ltr">
        <span class="typing-header">Privacy Policy</span>
        <!-- <span class="typing-paragraph">Updated on June 10, 2024</span> -->
      </div>
    </div>
    <div class="terms-content" dir="ltr">
      <div class="term-section">
        <p class="section-title">Introduction</p>
        <p class="section-content">{{ content.introduction }}</p>
      </div>
      <div class="term-section">
        <p class="section-title">1. Information We Collect</p>
        <p class="section-content">{{ content.informationWeCollect }}</p>
      </div>
      <div class="term-section">
        <p class="section-title">2. How We Use Your Information</p>
        <p class="section-content">{{ content.howWeUseYourInformation }}</p>
      </div>
      <div class="term-section">
        <p class="section-title">3. How We Protect Your Information</p>
        <p class="section-content">{{ content.howWeProtectYourInformation }}</p>
      </div>
      <div class="term-section">
        <p class="section-title">4. Sharing Your Information</p>
        <p class="section-content">{{ content.sharingYourInformation }}</p>
      </div>
      <div class="term-section">
        <p class="section-title">5. Cookies</p>
        <p class="section-content">{{ content.cookies }}</p>
      </div>
      <div class="term-section">
        <p class="section-title">6. Third-Party Links</p>
        <p class="section-content">{{ content.thirdPartyLinks }}</p>
      </div>
      <div class="term-section">
        <p class="section-title">7. Changes to This Privacy Policy</p>
        <p class="section-content">{{ content.changesToPrivacyPolicy }}</p>
      </div>
      <div class="term-section">
        <p class="section-title">8. Your Consent</p>
        <p class="section-content">{{ content.yourConsent }}</p>
      </div>
      <div class="term-section">
        <p class="section-title">9. Contacting Us</p>
        <p class="section-content">{{ content.contactingUs }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: {
        introduction: 'BookingIraq.com ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and use our services. By accessing or using BookingIraq.com, you agree to the terms of this Privacy Policy.',
        informationWeCollect: 'We may collect personal information and non-personal information from you in various ways, including when you visit our website, register on the site, make a booking, subscribe to our newsletter, respond to a survey, fill out a form, or interact with other activities, services, features, or resources we make available on our site. Personal Information: Name, Email Address, Phone Number, Payment Information, Booking Details (hotel, dates, etc.) Non-Personal Information: Browser Name, Type of Device, Operating System, Internet Service Provider, IP Address, Other Technical Information.',
        howWeUseYourInformation: 'We use the information we collect for various purposes, including: To process bookings and transactions, To improve customer service, To personalize user experience, To send periodic emails regarding your order or other products and services, To follow up with users after correspondence (live chat, email, or phone inquiries).',
        howWeProtectYourInformation: 'We implement a variety of security measures to maintain the safety of your personal information when you place a booking or enter, submit, or access your personal information. These measures include: Encryption of sensitive data, Secure networks, Regular security audits.',
        sharingYourInformation: 'We do not sell, trade, or otherwise transfer your personal information to outside parties except to provide the services you have requested, including: Trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. Hotels with which you have made a booking. Legal requirements such as responding to subpoenas, court orders, or legal processes.',
        cookies: 'Our site may use "cookies" to enhance user experience. Your web browser places cookies on your hard drive for record-keeping purposes and sometimes to track information about you. You may choose to set your web browser to refuse cookies or to alert you when cookies are being sent. If you do so, note that some parts of the site may not function properly.',
        thirdPartyLinks: 'Our website may contain links to other sites. We are not responsible for the privacy practices or the content of such websites. We encourage users to be aware when they leave our site and to read the privacy statements of any website that collects personally identifiable information.',
        changesToPrivacyPolicy: 'BookingIraq.com has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect.',
        yourConsent: 'By using our site, you consent to our privacy policy.',
        contactingUs: 'If you have any questions regarding this privacy policy, the practices of this site, or your dealings with this site, please contact us at: Email: info@bookingiraq.com, Phone: +971544679144, Address: UAE DUBAI - ALMUTEENA TECHNIC BUILDING - OFFICE 203 / 7.'
      }
    }
  }
}
</script>

<style scoped>
.container {
  margin: auto;
  font-family: "Cairo", sans-serif;
  width: 70%;
  direction: rtl;
}
.container-header {
  margin-top: 2rem;
  margin-bottom: 3rem;
}
.typing {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.typing-header {
  font-size: 26px;
  color: #ffb325;
  font-weight: 600;
}
.terms-content {
  background-color: #f9f9f9;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.term-section {
  margin-bottom: 1.5rem;
}
.section-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5rem;
}
.section-content {
  font-size: 16px;
  color: #555;
  line-height: 1.5;
  text-align: justify;
}
</style>
