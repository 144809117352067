<template>
  <div class="container">
    <div class="container-header">
      <div class="typing" dir="ltr">
        <span class="typing-header">Terms and Conditions</span>
        <span class="typing-pargraph">Updated on June 10, 2024</span>
      </div>
    </div>
    <div class="terms-content" dir="ltr">
      <div class="term-section">
        <p class="section-title">1. Introduction</p>
        <p class="section-content">{{ content.introduction }}</p>
      </div>
      <div class="term-section">
        <p class="section-title">2. Definitions</p>
        <p class="section-content">{{ content.definitions }}</p>
      </div>
      <div class="term-section">
        <p class="section-title">3. Acceptance of Terms</p>
        <p class="section-content">{{ content.acceptanceOfTerms }}</p>
      </div>
      <div class="term-section">
        <p class="section-title">4. Booking and Payment</p>
        <p class="section-content">{{ content.bookingAndPayment }}</p>
      </div>
      <div class="term-section">
        <p class="section-title">5. Cancellations and Refunds</p>
        <p class="section-content">{{ content.cancellationsAndRefunds }}</p>
      </div>
      <div class="term-section">
        <p class="section-title">6. User Obligations</p>
        <p class="section-content">{{ content.userObligations }}</p>
      </div>
      <div class="term-section">
        <p class="section-title">7. Privacy Policy</p>
        <p class="section-content">{{ content.privacyPolicy }}</p>
      </div>
      <div class="term-section">
        <p class="section-title">8. Intellectual Property</p>
        <p class="section-content">{{ content.intellectualProperty }}</p>
      </div>
      <div class="term-section">
        <p class="section-title">9. Limitation of Liability</p>
        <p class="section-content">{{ content.limitationOfLiability }}</p>
      </div>
      <div class="term-section">
        <p class="section-title">10. Governing Law</p>
        <p class="section-content">{{ content.governingLaw }}</p>
      </div>
      <div class="term-section">
        <p class="section-title">11. Changes to Terms</p>
        <p class="section-content">{{ content.changesToTerms }}</p>
      </div>
      <div class="term-section">
        <p class="section-title">12. Contact Information</p>
        <p class="section-content">{{ content.contactInformation }}</p>
      </div>
      <div class="term-section">
        <p class="section-title">13. Specific Hotel Policies</p>
        <p class="section-content">{{ content.specificHotelPolicies }}</p>
      </div>
      <div class="term-section">
        <p class="section-title">14. User Reviews</p>
        <p class="section-content">{{ content.userReviews }}</p>
      </div>
      <div class="term-section">
        <p class="section-title">15. Dispute Resolution</p>
        <p class="section-content">{{ content.disputeResolution }}</p>
      </div>
      <div class="term-section">
        <p class="section-title">16. Third-Party Services</p>
        <p class="section-content">{{ content.thirdPartyServices }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: {
        introduction: 'Welcome to BookingIraq.com. By using our website and services, you agree to comply with and be bound by the following terms and conditions. If you do not agree to these terms, please do not use our services.',
        definitions: '"We," "us," and "our" refer to BookingIraq.com. "User" and "you" refer to anyone who uses our website and services. "Service" refers to the online hotel booking services provided by BookingIraq.com.',
        acceptanceOfTerms: 'By accessing or using our services, you agree to these terms and conditions. If you do not agree with any part of these terms, you must not use our services.',
        bookingAndPayment: 'Availability: All bookings are subject to availability and confirmation from the hotel. Accuracy: Users must provide accurate and complete information during the booking process. Payment: Payment details must be provided at the time of booking. Full payment may be required to confirm a reservation. Price Changes: Prices are subject to change without notice but will be confirmed at the time of booking.',
        cancellationsAndRefunds: 'Cancellation Policies: Cancellation policies vary by hotel and will be clearly stated at the time of booking. Compliance: Users must comply with the specific cancellation policies of each hotel. Refunds: Refunds will be processed according to the hotel\'s policy. BookingIraq.com is not responsible for any hotel-specific refund policies.',
        userObligations: 'Responsible Use: Users must use the website responsibly and not for any unlawful or prohibited purpose. Harmful Behavior: Users must not engage in any behavior that could harm the website or its services. Confidentiality: Users are responsible for maintaining the confidentiality of their account information and password.',
        privacyPolicy: 'We are committed to protecting your privacy. Our Privacy Policy explains how we collect, use, and protect your information. By using our services, you agree to the terms of our Privacy Policy.',
        intellectualProperty: 'All content on the website, including text, graphics, logos, and software, is the property of BookingIraq.com or its licensors and is protected by intellectual property laws. Users may not reproduce, distribute, or create derivative works from our content without our express written permission.',
        limitationOfLiability: 'BookingIraq.com is not liable for any direct, indirect, incidental, or consequential damages arising from the use of our services. We do not guarantee the accuracy, completeness, or reliability of any information provided on our website.',
        governingLaw: 'These terms and conditions are governed by the laws of Iraq. Any disputes arising from these terms will be subject to the exclusive jurisdiction of the courts of Iraq.',
        changesToTerms: 'We reserve the right to modify these terms and conditions at any time. Users will be notified of any changes, and continued use of our services constitutes acceptance of the new terms.',
        contactInformation: 'For any questions or concerns regarding these terms and conditions, please contact us at: Email: info@bookingiraq.com, Phone: +971544679144, Address: UAE DUBAI - ALMUTEENA TECHNIC BUILDING - OFFICE 203 / 7',
        specificHotelPolicies: 'Each hotel may have its own set of rules and policies regarding check-in/check-out times, pet policies, etc. These will be clearly communicated during the booking process.',
        userReviews: 'Users are encouraged to submit reviews. However, all reviews must be constructive, appropriate, and comply with our guidelines. We reserve the right to remove any reviews that do not meet these standards.',
        disputeResolution: 'In the event of a dispute, users agree to attempt to resolve the issue through mediation or arbitration before pursuing legal action.',
        thirdPartyServices: 'If our website uses third-party services (such as payment processors), those services are governed by their own terms and conditions. BookingIraq.com is not liable for any issues arising from third-party services.'
      }
    }
  }
}
</script>

<style scoped>
.container {
  margin: auto;
  font-family: "Cairo", sans-serif;
  width: 70%;
  direction: rtl;
}
.container-header {
  margin-top: 2rem;
  margin-bottom: 3rem;
}
.typing {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.typing-header {
  font-size: 26px;
  color: #ffb325;
  font-weight: 600;
}
.terms-content {
  background-color: #f9f9f9;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.term-section {
  margin-bottom: 1.5rem;
}
.section-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5rem;
}
.section-content {
  font-size: 16px;
  color: #555;
  line-height: 1.5;
  text-align: justify;
}
</style>
