<template>
  <div :class="[{ rtl: locale === 'ar' || locale === 'kr' }, 'container']">
    <div class="typingFav" :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'">
      <span class="typingFav-header">{{ $t("message.favPlaces") }}</span>
      <span class="typingFav-pargraph">{{ $t("message.favDescription") }}</span>
    </div>
    <div v-if="loading" class="skeleton-loader">
      <div v-for="n in 4" :key="n" class="skeleton-card">
        <div class="skeleton-image"></div>
        <div class="skeleton-text" style="width: 60%;"></div>
        <div class="skeleton-big-text" style="width: 80%;"></div>
        <!-- <div class="skeleton-text" style="width: 40%;"></div> -->
      </div>
    </div>
    <div v-else-if="hotelsData.length" class="show-list-container mt-6">
      <div class="hotel-list mt-2">
        <div
          v-for="(hotel, index) in hotelsData"
          :key="index"
          @click="hotelClick(hotel.id)"
        >
          <VerticalHotelListing v-if="!hotel.is_faviorates" :hotel="hotel" />
        </div>
      </div>
    </div>
    <div v-else class="show-list-container2">
      <div class="hotel-not-found">
        <div>
          <v-icon class="text-orange" size="80" icon="mdi-alert-circle"></v-icon>
        </div>
        <div style="font-size: 28px; color: green">{{ $t("message.noFavPlaces") }}</div>
        <div :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'">
          {{ $t("message.addFavFromMain") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import VerticalHotelListing from "../components/HotelListComponents/VerticalHotelListing.vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

export default {
  name: "FaqMainList",
  components: {
    VerticalHotelListing,
  },
  setup() {
    const { t, locale } = useI18n();
    const router = useRouter();
    const store = useStore();
    const hotelsData = ref([]);
    const loading = ref(true);  // Added loading state

    const getHotels = async () => {
      try {
        await store.dispatch("getFaviorates");
        const faviorateData = store.getters.faviorate.data || [];
        hotelsData.value = faviorateData.map((item) => item.hotel);
        loading.value = false;  // Set loading to false when data is fetched
      } catch (error) {
        console.error("Error fetching hotels:", error);
        loading.value = false;  // Set loading to false in case of error
      }
    };

    onMounted(getHotels);

    watch(
      () => store.getters.faviorate.data,
      (newVal) => {
        if (newVal) {
          hotelsData.value = newVal.map((item) => item.hotel);
        }
      },
      { deep: true }
    );

    const hotelClick = (id) => {
      router.push({
        name: "Hotel",
        query: {
          id: id,
          governorate: "",
          checkin: "",
          checkout: "",
          room_no: 1,
          adult_no: 1,
          children_no: "",
        },
      });
    };

    return { hotelsData, hotelClick, t, locale, loading };
  },
};
</script>

<style scoped>
.container {
  margin: auto;
  font-family: "Cairo", sans-serif;
  width: 70%;
  margin-bottom: 3rem;
}
.rtl {
  direction: rtl;
}
.typingFav {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
}
.typingFav-header {
  font-size: 26px;
  color: #ffb325;
  font-weight: bold;
}
.typingFav-pargraph {
  font-size: 14px;
  color: #000;
}
.show-list-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}
.hotel-list {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}
.hotel-not-found {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 7rem 5rem;
  align-items: center;
  gap: 2rem;
}

/* Skeleton Loader Styles */
.skeleton-loader {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-around; */
  align-items: center;
  gap: 5px;
}
.skeleton-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 230px;
  border-radius: 10px;
  padding: 10px;
  background: #f0f0f0;
}
.skeleton-image {
  width: 100%;
  height: 150px;
  background: #e0e0e0;
  border-radius: 10px;
}
.skeleton-text {
  height: 20px;
  background: #e0e0e0;
  border-radius: 4px;
  margin-top: 10px;
}
.skeleton-big-text {
  height: 80px;
  background: #e0e0e0;
  border-radius: 4px;
  margin-top: 10px;
}
</style>
