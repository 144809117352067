<template>
  <div class="w-[90%] md:w-[70%] mx-auto mt-5" :dir="locale === 'en' ? 'ltr' : 'rtl'">
    <div class="container-header">
      <div class="typing">
        <span class="typing-header mb-1">
          {{ t('message.availableServices') }}
        </span>
      </div>
    </div>
    <div class="masonry">
      <HotelChecksServices
        v-for="(item, index) in hotelFeatures"
        :key="index"
        :title="item[`${locale}_name`]"
        :icon="item.icon"
        :services="item.features"
        class="masonry-item"
      />
    </div>
  </div>
</template>

<script>
import HotelChecksServices from "../ShowHotelComponents/HotelChecksServices.vue";
import { useI18n } from "vue-i18n";

export default {
  name: "HotelThingsProvided",
  props: {
    hotelsInfo: {},
  },
  /* eslint-disable */
  components: {
    HotelChecksServices,
  },
  setup() {
    const { locale, t } = useI18n();
    return {
      locale,
      t
    };
  },
  computed: {
    hotelFeatures(){
      return this.hotelsInfo.features?.filter(f => f.features.length > 0) || []
    }
  }
};
</script>

<style>
.masonry {
  column-count: 3;
  column-gap: 1rem;
}

.masonry-item {
  break-inside: avoid;
  margin-bottom: 1rem;
}

.main-container {
  width: 70%;
  margin: auto;
  font-family: "Cairo", sans-serif;
  margin-top: 3rem;
}
.container-header {
  margin: auto;
}
.typing {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.typing-header {
  font-size: 26px;
  color: #ffb325;
  font-weight: bold;
}
.typing-pargraph {
  font-size: 14px;
  color: #000;
}
.hotel-services {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 3rem;
}
</style>
