<template>
  <div :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'" class="container">
    <div class="container-header">
      <div class="typing">
        <span class="typing-header">{{ $t('message.faqHeader') }}</span>
        <span class="typing-paragraph">{{ $t('message.faqParagraph') }}</span>
      </div>
      <div class="header-text-bottom">
        <button
          v-for="(button, index) in buttons"
          :key="index"
          :class="[isActive(index) ? [activeClass, 'activeBtn'] : [inactiveClass, 'inactiveBtn']]"
          @click="handleButtonClick(index + 1)"
          type="button"
        >
          {{ $t(button) }}
        </button>
      </div>
      <div>
        <ListOfQuestions :faq="faq" :loading="loading" @toggle-info="toggleInfo" />
      </div>
    </div>
  </div>
</template>

<script>
import ListOfQuestions from "./ListOfQuestions.vue";
import { useI18n } from "vue-i18n";
import { ref, onMounted } from "vue";
import { useStore } from 'vuex';

export default {
  components: {
    ListOfQuestions,
  },
  setup() {
    const { locale } = useI18n();
    const activeIndex = ref(0);
    const faq = ref([]);
    const loading = ref(false);
    const store = useStore();
    const buttons = [
      'message.hotels',
      'message.bookingMethods',
      'message.customerService',
      'message.touristPlaces',
    ];

    const activeClass = 'text-white outlined-ring-blue-300 font-medium rounded-full text-sm px-10 py-2.5 text-center';
    const inactiveClass = 'text-blue rounded-full text-sm px-10 py-2.5 go-to-contact-us text-center';

    const setActive = (index) => {
      activeIndex.value = index;
    };

    const isActive = (index) => {
      return activeIndex.value === index;
    };

    const fetchFaq = async (departmentType) => {
      try {
        loading.value = true;
        await store.dispatch('getFaq', { take: 15, skip: 0, department_type: departmentType });
        faq.value = store.getters.faq.data;
      } catch (error) {
        console.error('Error fetching FAQ:', error);
      } finally {
        loading.value = false;
      }
    };

    const handleButtonClick = (departmentType) => {
      setActive(departmentType - 1);
      fetchFaq(departmentType);
    };

    const toggleInfo = (index) => {
      faq.value[index].showInfo = !faq.value[index].showInfo;
    };

    onMounted(() => {
      handleButtonClick(1); // Fetch FAQs for the first department type when component is mounted
    });

    return { locale, buttons, isActive, handleButtonClick, faq, loading, activeClass, inactiveClass, toggleInfo };
  },
};
</script>

<style scoped>
.container {
  margin: auto;
  font-family: "Cairo", sans-serif;
  width: 90%;
}

.typing {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.typing-header {
  font-size: 26px;
  color: #ffb325;
  font-weight: 600;
}
.typing-paragraph {
  font-size: 14px;
  color: #000;
}
.header-text-bottom {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
button {
  font-weight: 600;
}
.activeBtn {
  background-color: #003b7d;
}
.inactiveBtn {
  border: 2px solid #003b7d;
}
.go-to-contact-us:hover {
  background-color: #003b7d;
  opacity: 0.5;
  color: #fff !important;
  transition: all 0.5s ease-in-out;
}

/* Responsive Design */
@media (min-width: 768px) {
  .container {
    width: 80%;
  }
  .typing-header {
    font-size: 28px;
  }
  .typing-paragraph {
    font-size: 16px;
  }
  .header-text-bottom button {
    font-size: 1em;
    padding: 10px 20px;
  }
}

@media (min-width: 1024px) {
  .container {
    width: 70%;
  }
  .typing-header {
    font-size: 30px;
  }
  .typing-paragraph {
    font-size: 18px;
  }
  .header-text-bottom button {
    font-size: 1.2em;
    padding: 12px 24px;
  }
}
</style>
