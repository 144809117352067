<template>
  <div class="container">
    <div class="container-header">
      <div class="typing" dir="rtl">
        <span class="typing-header">أدارة الحساب</span>
        <span class="typing-pargraph"
          >قم بتحسين تجربتك عبر تخصيص اعدادات حسابك</span
        >
      </div>
    </div>
    <div>
      <div v-if="activeTab == 1" style="margin-bottom: 1rem">
        <span class="typing-header" style="font-size: 22px"
          >المعلومات الشخصية</span
        >
      </div>
      <div v-if="activeTab == 2" style="margin-bottom: 1rem">
        <span class="typing-header" style="font-size: 22px"
          >اعدادات اللغة والعملة</span
        >
      </div>
      <div v-if="activeTab == 3" style="margin-bottom: 1rem">
        <span class="typing-header" style="font-size: 22px"
          >اعدادت الخصوصية والامان</span
        >
      </div>
      <div class="page-divider">
        <v-card v-if="activeTab == 1" class="card1">
          <h2 class="text-lg font-bold">اعادة تعيين كلمة المرور</h2>
          <v-form @submit.prevent="updateProfile">
            <label class="text-md mb-5 block">
              صورة الملف الشخصي
              <VImageUploader
                v-model="generalForm.image"
                class="mt-2"
                :isInvalid="false"
              />
            </label>
            <label class="text-md">
              الاسم الكامل
              <v-text-field
                append-inner-icon="mdi-account"
                variant="outlined"
                v-model="generalForm.name"
              ></v-text-field>
            </label>
            <label class="text-md">
              رقم الهاتف
              <v-text-field
                append-inner-icon="mdi-phone"
                variant="outlined"
                type="number"
                v-model="generalForm.phone"
              ></v-text-field>
            </label>
            <label class="text-md">
              البريد الالكتروني
              <v-text-field
                placeholder="البريد الالكتروني"
                append-inner-icon="mdi-email"
                variant="outlined"
                v-model="generalForm.email"
              ></v-text-field>
            </label>
            <label class="text-md">
              الدولة
              <v-text-field
                append-inner-icon="mdi-airplane"
                variant="outlined"
                v-model="generalForm.country"
              ></v-text-field>
            </label>
            <label class="text-md">
              المدينة
              <v-text-field
                append-inner-icon="mdi-city"
                variant="outlined"
                v-model="generalForm.city"
              ></v-text-field>
            </label>
            <button
              type="submit"
              class="py-2 px-2 text-white text-md rounded-lg text-[14px]"
            >
              <v-progress-circular
                v-if="isLoading"
                indeterminate
                :size="14"
              ></v-progress-circular>
              <template v-else> تحديث </template>
            </button>
          </v-form>
        </v-card>

        <!-- اعدادات اللغة والعملة -->
        <v-card v-if="activeTab == 2" class="card1">
          <div class="list-item">
            <span style="width: 12%"> العملة </span>
            <span style="display: flex; flex-direction: column; width: 75%">
              <span style="color: #888"> الدولار الامريكي </span>
            </span>
            <span> </span>
          </div>
          <div class="list-item">
            <span style="width: 12%"> اللغة </span>
            <span style="display: flex; flex-direction: column; width: 75%">
              <span style="color: #888">العربية</span>
            </span>
            <span> </span>
          </div>
        </v-card>

        <!-- اعدادت الخصوصية والامان-->
        <v-card v-if="activeTab == 3" class="card1">
          <h2 class="text-lg font-bold">اعادة تعيين كلمة المرور</h2>
          <v-form @submit.prevent="updatePassword">
            <label class="text-md">
              كلمة المرور
              <v-text-field
                v-model="passwordForm.password"
                variant="outlined"
                class="mt-2"
                :rules="isValidPassword"
              />
            </label>
            <label class="text-md">
              تأكيد كلمة المرور
              <v-text-field
                v-model="passwordForm.confirm_password"
                variant="outlined"
                class="mt-2"
                :rules="confirmPasswordValidation"
              />
            </label>
            <button
              type="submit"
              class="py-2 px-2 text-white text-md rounded-lg text-[14px]"
            >
              <v-progress-circular
                v-if="isLoading"
                indeterminate
                :size="14"
              ></v-progress-circular>
              <template v-else> تحديث </template>
            </button>
          </v-form>
        </v-card>

        <v-card class="card2">
          <div
            @click="changeActiveTab(1)"
            :class="{ 'blue-item': activeTab === 1 }"
          >
            <i class="far fa-user ml-3"></i>
            <span>المعلومات الشخصية</span>
          </div>
          <div
            @click="changeActiveTab(2)"
            :class="{ 'blue-item': activeTab === 2 }"
          >
            <i class="fas fa-sliders ml-3"></i>
            <span>اللغة و العملة</span>
          </div>
          <div
            @click="changeActiveTab(3)"
            :class="{ 'blue-item': activeTab === 3 }"
          >
            <i class="fas fa-lock ml-3"></i>
            <span>اعادة تعيين كلمة المرور</span>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from "vue";
import { useStore } from "vuex";
import { toast } from "vue-sonner";
import VImageUploader from "./ui/VImageUploader.vue";

const store = useStore();
const userInfo = store.getters.userInfo.userAuth;
const activeTab = ref(1);
const isLoading = ref(false);
const generalForm = reactive({
  image: userInfo.image,
  name: userInfo.name,
  email: userInfo.email,
  phone: userInfo.phone,
  country: userInfo.country,
  city: userInfo.city,
});
const passwordForm = reactive({
  password: "",
  confirm_password: "",
});
const isValidPassword = [
  (value) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!regex.test(value)) {
      return "كلمة المرور يجب أن تكون على الأقل 8 أحرف وتتضمن حرفًا كبيرًا، حرفًا صغيرًا، رقمًا، ورمزًا خاصًا.";
    }
    return true;
  },
];
const confirmPasswordValidation = [
  (value) => {
    if (value === passwordForm.password) return true;
    return "كلمة المرور غير متطابقة!";
  },
];

const changeActiveTab = (tab) => {
  activeTab.value = tab;
};

const updateProfile = async (event) => {
  const fromStatus = await event;
  if (!fromStatus.valid) return;
  isLoading.value = true;
  store.dispatch("updateAuthUser", { ...generalForm }).then(() => {
    isLoading.value = false;
    toast.success("تم تحديث المعلومات بنجاح");
  });
};

const updatePassword = async (event) => {
  const fromStatus = await event;
  if (!fromStatus.valid) return;

  isLoading.value = true;
  store.dispatch("updateAuthPassword", { ...passwordForm }).then(() => {
    isLoading.value = false;
    toast.success("تم تحديث كلمة المرور بنجاح");
  });
};
</script>

<style scoped>
.container {
  margin: auto;
  font-family: "Cairo", sans-serif;
  width: 70%;
  /* height: 420px; */
  direction: rtl;
}
.container-header {
  margin-top: 2rem;
  margin-bottom: 3rem;
}
.page-divider {
  display: flex;
  gap: 20px;
  width: 100%;
}
.page-divider .card1 {
  width: 75%;
  padding: 2rem 2rem 2rem 2rem;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  height: 50%;
  font-size: 12px;
}
.page-divider .card2 {
  width: 25%;
  display: flex;
  flex-direction: column;
  height: 50%;
}
.page-divider .card2 .blue-item {
  background-color: #003b7d;
  color: #fff;
}
.page-divider .card2 div {
  padding: 1rem;
  cursor: pointer;
}
.list-item {
  display: flex;
  gap: 2rem;
  font-size: 15px;
  justify-content: space-between;
}
.typing {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.typing-header {
  font-size: 26px;
  color: #ffb325;
  font-weight: 600;
}
.typing-pargraph {
  font-size: 14px;
  color: #000;
}
.header-text-bottom {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 10px;
}
button {
  background-color: #003b7d;
  font-weight: 600;
}
.go-to-contact-us {
  border: 2px solid #003b7d;
  color: #003b7d;
  background-color: #fff;
}
.go-to-contact-us:hover {
  background-color: #003b7d;
  opacity: 0.5;
  color: #fff !important;
  transition: all 0.5s ease-in-out;
}
</style>
