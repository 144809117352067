<template>
  <div v-if="showOffers || loading" :class="[{ rtl: locale === 'ar' || locale === 'kr' }, 'container']">
    <div :dir="locale === 'ar' ? 'rtl' : 'ltr'" class="container-header" :style="locale === 'ar' || locale === 'kr' ? '' : ''">
      <div class="typingRec" >
        <span class="typing-header">
          {{ slide[`${locale}_title`] }}
        </span>
        <span class="typing-paragraph">
          {{ slide[`${locale}_description`] }}
        </span>
      </div>
    </div>

    <div class="content">
      <template v-if="loading">
        <div v-for="n in 6" :key="n" class="card-container skeleton-card-container">
          <div class="skeleton-image-container">
            <img src="@/assets/imgs/img.jpg" alt="" />
          </div>
          <div class="skeleton-card-text">
            <div class="skeleton-text" style="width: 60%"></div>
            <div class="skeleton-text" style="width: 80%"></div>
            <div class="skeleton-text" style="width: 40%"></div>
          </div>
        </div>
      </template>
      <template v-else>
        <div v-for="(item, index) in sliders" :key="index">
          <div class="card-container shadow hover:bg-gray-100">
            <div class="image-container">
              <div>
                <div class="image-text rounded-full" :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'">
                  <span>
                    <span class="city-text">{{ locale === "ar" ? item.city?.ar_name : item.city?.en_name }}</span>
                  </span>
                </div>
                <div class="image-icons rounded-full" :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'">
                  <span class="rounded-full" @click="toggleFavorite(item)">
                    <v-icon
                      size="20"
                      :class="item.is_faviorate ? 'text-orange' : 'text-gray'"
                      :icon="item.is_faviorate ? 'mdi-star' : 'mdi-star-outline'"
                    ></v-icon>
                  </span>
                  <span class="rounded-full" @click="search(item)">
                    <v-icon size="20" class="text-orange" icon="mdi-magnify"></v-icon>
                  </span>
                </div>
              </div>
              <img :src="item.image" alt="" />
            </div>
            <div class="card-text">
              <div class="header-text-container" :style="locale === 'ar' || locale === 'kr' ? '' : 'flex-direction: row-reverse;'">
                <h1>{{ item.title }}</h1>
                <span class="font-medium rounded-full text-center start-count">
                  <v-icon class="text-orange" size="18" icon="mdi-star"></v-icon>
                  <span>{{ item.stars }}</span>
                </span>
              </div>
              <div :style="locale === 'ar' || locale === 'kr' ? '' : 'flex-direction: row-reverse;'">
                <v-icon
                  class="text-orange"
                  v-for="(starItem, indexItem) in item.stars"
                  :key="indexItem"
                  size="14"
                  icon="mdi-star"
                ></v-icon>
                <span class="rating-count">{{ item.stars }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, watch } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

const props = defineProps({
  slide: {
    type: Object,
    required: true,
  },
  loading: {
    type: Boolean,
    required: true,
  },
});

const router = useRouter();
const store = useStore();
const showOffers = ref(false);
const sliders = ref([]);
const { locale } = useI18n();
const loading = ref(true);

const adult_no = ref(1);
const children_no = ref(0);
const governorate = ref("");
const checkin = ref("");
const checkout = ref("");
const room_no = ref(1);

const search = (item) => {
  router.push({
    name: "Hotel",
    query: {
      id: item.id,
      governorate: governorate.value,
      checkin: checkin.value,
      checkout: checkout.value,
      room_no: room_no.value,
      adult_no: adult_no.value,
      children_no: children_no.value,
    },
  });
};

const toggleFavorite = async (item) => {
  try {
    if (item.is_faviorate) {
      await store.dispatch("addOrRemoveFavorite", { hotel_id: item.id });
    } else {
      await store.dispatch("addOrRemoveFavorite", { hotel_id: item.id });
    }
    item.is_faviorate = !item.is_faviorate;
  } catch (error) {
    console.error("Error toggling favorite:", error);
  }
};

// Watch for changes in sliders prop to handle loading and data population
watch(
  () => props.slide,
  (newVal) => {
      sliders.value = newVal.sliders.map((offer) => offer.child).filter((child) => child !== null);
      showOffers.value = sliders.value.length > 0;
      loading.value = false;
  },
  { immediate: true }
);
</script>

<style scoped>
.container {
  margin: auto;
  font-family: "Cairo", sans-serif;
  width: 70%;
  margin-bottom: 3rem;
}
.rtl {
  direction: rtl;
}
.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  width: 100%;
}
.card-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 315px;
  border-radius: 10px;
}
.card-container:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: all 0.4s ease-in-out;
}
.card-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 16px;
  padding: 10px 15px 15px 15px;
  gap: 8px;
  width: 100%;
  height: 100%;
}
.image-container {
  height: 230px;
  width: 315px;
  position: relative;
}
.image-container img {
  width: 100%;
  border-radius: 10px;
  height: 100%;
  object-fit: cover;
  display: block;
}
.typingRec {
  margin-bottom: 35px;
  display: flex;
  flex-direction: column;
}
.typing-header {
  font-size: 26px;
  color: #ffb325;
  font-weight: bold;
}
.typing-paragraph {
  font-size: 14px;
  color: #000;
}
.header-text-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.start-count {
  font-size: 16px;
  background-color: #fafafa;
  padding: 2px 8px;
  display: flex;
  align-items: center;
  gap: 1px;
}
.rating-count {
  font-size: 12px;
  margin-right: 3px;
}
.image-text {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 5px 12px;
  margin: 5px;
  font-size: 12px;
}
.image-icons {
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  margin: 5px;
  font-size: 12px;
  display: flex;
  gap: 4px;
}
.image-icons span {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 6px;
}
.city-text {
  color: #ffb325;
}
.container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Skeleton Loader Styles */
.skeleton-card-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 315px;
  border-radius: 10px;
}
.skeleton-image-container {
  height: 230px;
  width: 315px;
  background-color: #e0e0e0;
  border-radius: 10px;
}
.skeleton-image-container img {
  width: 100%;
  border-radius: 10px;
  height: 100%;
  object-fit: cover;
  display: block;
}
.skeleton-card-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 16px;
  padding: 10px 15px 15px 15px;
  gap: 8px;
  width: 100%;
  height: 100%;
}
.skeleton-text {
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 4px;
}
.text-gray {
  color: gray;
}
</style>
