<template>
  <div class="login-container">
    <div class="img-container2">
      <img src="@/assets/imgs/landscape.jpg" alt="" />
      <div class="overlay"></div>
      <div class="booking-text-login">Bookingiraq</div>
      <div class="bottom-text">
        اكبر منصة لحجز الفنادق في العراق. من خلال BookingIraq يمكنك حجز الفنادق من
        المدن العراقية المفضلة لديك بكل سهولة واريحية. كل ما عليك فعله هو تحديد الوجهة
        الخاصة بك, عدد الاشخاص ومدة الاقامة ونحن نقوم بالباقي
      </div>
    </div>
    <div class="right-side" v-if="!verifyOtp" dir="rtl">
      <div
        style="font-size: 26px; color: #ffb325; font-weight: bold; margin-bottom: 2rem"
      >
        أنشاء حساب جديد
      </div>
      <div style="width: 100%">
        <v-locale-provider rtl>
          <div>
            <div class="mb-2">الاسم</div>
            <v-text-field
              placeholder="الاسم "
              append-inner-icon="mdi-account"
              variant="outlined"
              v-model="postData.name"
            ></v-text-field>
          </div>
        </v-locale-provider>
      </div>

      <div>
        <v-locale-provider rtl>
          <div class="mb-2">الهاتف</div>
          <v-text-field
            placeholder="الهاتف "
            append-inner-icon="mdi-phone"
            variant="outlined"
            type="number"
            v-model="postData.phone"
          ></v-text-field>
        </v-locale-provider>
      </div>
      <div>
        <v-locale-provider rtl>
          <div class="mb-2">البريد الالكتروني</div>
          <v-text-field
            placeholder="البريد الالكتروني"
            append-inner-icon="mdi-email"
            variant="outlined"
            v-model="postData.email"
          ></v-text-field>
        </v-locale-provider>
      </div>

      <div>
        <v-locale-provider rtl>
          <div class="mb-2">الرمز السري</div>
          <v-text-field
            :prepend-inner-icon="visible ? ' mdi-eye-off' : 'mdi-eye'"
            :type="visible ? 'text' : 'password'"
            placeholder="الرمز السري"
            append-inner-icon="mdi-lock-outline"
            variant="outlined"
            @click:prepend-inner="visible = !visible"
            v-model="postData.password"
          ></v-text-field>
        </v-locale-provider>
      </div>
      <div style="display: flex; justify-content: space-between; gap: 5px">
        <div style="width: 100%">
          <v-locale-provider rtl>
            <div class="mb-2">الدولة</div>
            <v-text-field
              placeholder="الدولة"
              append-inner-icon="mdi-airplane"
              variant="outlined"
              v-model="postData.country"
            ></v-text-field>
          </v-locale-provider>
        </div>
        <v-locale-provider rtl>
          <div style="width: 100%">
            <div class="mb-2">المدينة</div>
            <v-text-field
              placeholder=" المدينة"
              append-inner-icon="mdi-city"
              variant="outlined"
              v-model="postData.city"
            ></v-text-field>
          </div>
        </v-locale-provider>
      </div>
      <div class="show-more-button">
        <!-- <router-link active-class="active" class="hover:text-red duration-500" to="/"> -->
        <button
          type="button"
          class="text-white bg-[#003b7d] hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-6 py-3 text-center"
          @click="post"
        >
          أنشاء حساب
        </button>
        <!-- </router-link> -->
      </div>
      <div class="mt-2 mr-2" style="font-size: 15px">
        هل لديك حساب بالفعل,
        <router-link
          active-class="active"
          class="hover:text-red duration-500"
          to="/login"
        >
          <span style="color: #ffb325; cursor: pointer"> قم بستجيل الدخول</span>
        </router-link>
      </div>
      <div class="mr-2" style="font-size: 15px; margin-top: 1rem">
        أو استخدم إحدى هذه الخيارات:
      </div>
      <div class="sign-in-with">
        <div style="background-color: #fafafa; padding: 0.5rem 1rem; border-radius: 10px">
          <v-icon class="ml-2" size="medium" icon="mdi-google" color="red"></v-icon>
          تسجيل عن طريق جوجل
        </div>
        <div style="background-color: #fafafa; padding: 0.5rem 1rem; border-radius: 10px">
          <v-icon class="ml-2" size="medium" icon="mdi-facebook" color="blue"></v-icon>
          تسجيل عن طريق الفيسبوك
        </div>
      </div>
    </div>

    <div class="right-side" v-if="verifyOtp" dir="rtl">
      <div
        style="font-size: 26px; color: #ffb325; font-weight: bold; margin-bottom: 2rem"
      >
        ادخل الرمز المستملم
      </div>
      <div class="mb-12">تاكد من هاتفك و ادخل الرمز المستلم الذي تم ارساله لك</div>

      <div>
        <v-locale-provider rtl>
          <div class="mb-2">الرمز التاكيدي</div>
          <v-text-field
            placeholder="الرمز التاكيدي "
            append-inner-icon="mdi-phone"
            variant="outlined"
            type="number"
            v-model="postDataOtp.otp"
          ></v-text-field>
        </v-locale-provider>
      </div>

      <div class="show-more-button">
        <router-link active-class="active" class="hover:text-red duration-500" to="/">
          <button
            type="button"
            class="text-white bg-[#003b7d] hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-6 py-3 text-center"
          >
            ارسال
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import {useRouter} from "vue-router"
export default {
  setup() {
    const visible = ref(false); // Initializing visible as a reactive ref
    const verifyOtp = ref(false); // Initializing visible as a reactive ref
    const postData = ref({
      name: "",
      email: "",
      password: "",
      phone: "",
      country: "",
      city: "",
      passcode: "1234",
    });
    const postDataOtp = ref({
      phone: "",
      otp: "",
    });
    const store = useStore(); // Access the Vuex store
    const router = useRouter()

    const post = async () => {
      try {
        await store.dispatch("register", postData.value).then(() => {
          verifyOtp.value = false; // Setting verifyOtp to true
          postDataOtp.value.phone = postData.value.phone;
          router.push("/");
        });
        // Login successful, you can redirect or perform other actions here
      } catch (error) {
        // Handle login error
        console.error("Login error:", error);
      }
    };
    const postOtp = () => {
      // try {
      // await store.dispatch("verifyOtp", postData.value).then(() => {
      // router.push("/login");
      //   });
      //   // Login successful, you can redirect or perform other actions here
      // } catch (error) {
      //   // Handle login error
      //   console.error("Login error:", error);
      // }
    };

    return {
      postData,
      post,
      postOtp,
      visible,
      postDataOtp,
      verifyOtp,
    };
  },
};
</script>

<style>
.login-container {
  font-family: "Cairo", sans-serif;

  width: 70%;
  height: 100vh;
  margin: auto;
  display: flex;
  /* border: 1px solid #000; */
}
.img-container2 {
  width: 50%;
  height: 120%;
  position: relative;
}
.img-container2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.right-side {
  width: 50%;
  margin: auto;
  padding: 2rem;
  /* display:flex; */
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(11, 104, 175, 0);
  background: linear-gradient(to bottom, #003b7d, rgba(0, 145, 255, 0.4));
  z-index: 5;
}
.booking-text {
  position: absolute;
  top: 30%;
  left: 35%;
  color: #fff;
  font-size: 30px;
  z-index: 15;
}
.bottom-text {
  position: absolute;
  width: 80%;
  top: 40%;
  left: 10%;
  color: #fff;
  font-size: 20px;
  z-index: 15;
  margin: auto;
  text-align: center;
}
@media (max-width: 1400px) {
  .login-container {
    width: 80%;
    height: 100vh;
    margin: auto;
    /* border: 1px solid #000; */
  }
  .booking-text {
    left: 30%;
  }
}
.show-more-button {
  margin-top: 10px;
}
.show-more-button button {
  background-color: #003b7d;
}
.show-more-button button:hover {
  background-color: #043961;
  transition: all 0.5s ease-in-out;
}
.sign-in-with {
  display: flex;
  justify-content: space-around;
  margin-top: 1.5rem;
}
.sign-in-with div {
  text-align: center;
  cursor: pointer;
}
@media (max-width: 1480px) {
  .sign-in-with {
    flex-direction: column;
    /* margin:auto; */
    align-items: center;
    gap: 20px;
  }
  .sign-in-with div {
    width: 100%;
    text-align: center;
    cursor: pointer;
  }
}
@media (max-width: 1100px) {
  .img-container2 {
    display: none;
  }
  .right-side {
    width: 100% !important;
    margin: auto;
    padding: 2rem;
  }
  .login-container {
    width: 100%;
  }
}
/* 
@media (max-width: 1022px) {
  .footer-container {
    height: 450px;
  }
} */
</style>
