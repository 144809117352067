<template>
  <div
    class="mx-auto mb-4"
    :dir="locale === 'en' ? 'ltr' : 'rtl'"
    :class="['mx-auto mb-4', compact ? 'w-full' : 'w-[90%] md:w-[70%]']"
  >
    <div class="container-header-hotel">
      <div class="header-title">
        <div
          :class="[
            'text-orange-500 font-bold',
            compact ? 'text-sm' : 'text-xl',
          ]"
        >
          {{ $t("message.guestRatings") }}
        </div>
        <div v-if="!compact" style="color: #000; font-size: medium">
          ({{ totalReviews }})
          {{ $t("message.reviews") }}
        </div>
      </div>
      <div v-if="!compact" class="left-side-header">
        <div>
          <span
            style="color: orange; text-decoration: underline; cursor: pointer"
            class="rounded-full text-center"
            @click="dialog = true"
          >
            <span v-if="!isRtl">?</span>
            {{ $t("message.viewAllReviews") }}
          </span>
        </div>
      </div>
    </div>
    <div class="text-center pa-4" style="font-family: 'Cairo', sans-serif">
      <v-dialog v-model="dialog" width="auto">
        <v-card width="1050">
          <v-card-title
            class="d-flex justify-space-between align-center"
            :style="{ direction: isRtl ? 'rtl' : 'ltr' }"
          >
            <div style="color: orange; font-weight: 700">
              {{ $t("message.ratings") }}
            </div>
            <v-btn
              icon="mdi-close"
              variant="text"
              @click="dialog = false"
            ></v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <div
            class="modal-container p-3"
            :style="{ direction: isRtl ? 'rtl' : 'ltr' }"
          >
            <div>
              <div class="header-title">
                <span style="color: #000; font-size: medium"
                  >{{ $t("message.exceptional") }} - ({{ totalReviews }})
                  {{ $t("message.reviews") }}</span
                >
              </div>
              <div class="left-side-header">
                <span class="start-count mr-2 rounded-full">
                  <v-icon
                    class="text-orange"
                    size="14"
                    icon="mdi-star"
                  ></v-icon>
                  <v-icon
                    class="text-orange"
                    size="14"
                    icon="mdi-star"
                  ></v-icon>
                  <v-icon
                    class="text-orange"
                    size="14"
                    icon="mdi-star"
                  ></v-icon>
                  <v-icon
                    class="text-orange"
                    size="14"
                    icon="mdi-star"
                  ></v-icon>
                  <v-icon
                    class="text-orange"
                    size="14"
                    icon="mdi-star"
                  ></v-icon>
                  <span class="rating-count">4.8/5</span>
                </span>
              </div>
            </div>
            <div class="guest-ratings my-2">
              <span style="color: orange; font-size: 22px; font-weight: 800">{{
                $t("message.guestRatings")
              }}</span>
            </div>
            <div class="rating-comments">
              <HotelRatingCommentsComponent
                v-for="comment in comments.slice(0, 6)"
                :key="comment.id"
                :comment="comment"
                class="rating-comment"
              />
            </div>
          </div>
        </v-card>
      </v-dialog>
    </div>
    <swiper-container
      ref="swiperRef"
      :slides-per-view="compact ? 1 : 1.3"
      space-between="10"
      :breakpoints="swiperBreakpoints"
    >
      <swiper-slide
        v-for="comment in comments.slice(0, 6)"
        :key="comment.id"
        :class="[
          'overflow-hidden relative rounded shadow mb-4 rounded-lg w-full h-[150px]',
          compact ? 'bg-[#FAFAFA]' : 'bg-white',
        ]"
      >
        <HotelRatingCommentsComponent :comment="comment" />
      </swiper-slide>
    </swiper-container>
    <div
      :class="[
        'py-2 shadow flex justify-between items-center rounded-lg px-5',
        compact ? 'bg-[#FAFAFA]' : 'bg-white',
      ]"
    >
      <div style="font-size: 14px">{{ $t("message.last20Reviews") }}</div>
      <div dir="rtl" :class="{'rotate-180': locale === 'en'}">
        <v-icon
          style="color: #003b7d"
          size="25"
          class="mr-2"
          icon="mdi-arrow-right-thin"
          @click="swiperRef?.swiper.slidePrev()"
        ></v-icon>
        <v-icon
          style="color: #003b7d"
          size="25"
          icon="mdi-arrow-left-thin"
          @click="swiperRef?.swiper.slideNext()"
        ></v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import HotelRatingCommentsComponent from "@/components/ShowHotelComponents/HotelRatingCommentsComponent.vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "HotelRating",
  props: {
    compact: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HotelRatingCommentsComponent,
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const { locale, t } = useI18n();
    const swiperRef = ref()
    const dialog = ref(false);
    const isRtl = ref(locale.value === "ar");
    const swiperBreakpoints = props.compact
      ? {}
      : {
          640: {
            slidesPerView: 2.3,
          },
          1024: {
            slidesPerView: 3.5,
          },
        };

    // watch(locale, (newLocale) => {
    //   isRtl.value = newlocale === 'ar' || locale === 'kr';
    // });

    const hotelId = route.query.id;

    onMounted(() => {
      store.dispatch("getComments", hotelId);
    });

    const comments = computed(() => store.getters.comments || []);
    const totalReviews = computed(() => store.getters.totalReviews || 0);

    return {
      locale,
      t,
      dialog,
      isRtl,
      comments,
      totalReviews,
      swiperBreakpoints,
      swiperRef
    };
  },
};
</script>

<style scoped>
.container-header-hotel {
  display: flex;
  justify-content: space-between;
}
.container-header {
  font-family: "Cairo", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #000;
  padding: 15px;
  position: relative;
}
.RatingCards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.rating-comments {
  overflow: hidden;
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
  flex-direction: row;
  gap: 15px;
  flex-wrap: wrap;
}
.rating-comment {
  width: 100%;
  max-width: 320px;
}
.rating-section {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 5px 12px;
  margin-top: 10px;
  border-radius: 5px;
}
.guest-ratings {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
