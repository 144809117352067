<template>
  <div v-if="localLoading || showOffers" :class="[{ rtl: locale === 'ar' || locale === 'kr' }, 'container']">
    <div class="typing" :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'">
      <span class="typing-header">{{ slide[`${locale}_title`] }}</span>
      <span class="typing-paragraph">{{ slide[`${locale}_description`] }}</span>
    </div>
    <div class="content">
      <template v-if="localLoading">
        <div class="grid-container">
          <div v-for="n in 6" :key="n" class="grid-item skeleton-grid-item">
            <img src="@/assets/imgs/img.jpg" alt=""/>
            <div class="skeleton-card-text">
              <div class="skeleton-text" style="width: 60%"></div>
              <div class="skeleton-text" style="width: 80%"></div>
              <div class="skeleton-text" style="width: 40%"></div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="grid-container">
          <div
            v-for="(slider, index) in sliders"
            :key="index"
            class="grid-item"
            :class="'item-' + index"
            @click="hotelClick(slider)"
          >
            <h1 v-if="slider" class="image-text" :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'">
              <span>{{ slider.hotelsCount }}</span>
              {{ t("message.placesToStay") }}
              <span class="city-text">{{ locale === "ar" ? slider.title : slider.en_title }}</span>
            </h1>
            <div class="overlay-img"></div>
            <img v-if="slider" :src="slider.imageSrc || '/assets/images/img.jpg'" alt=""/>
            <img v-if="!slider" src="@/assets/imgs/img.jpg" alt=""/>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

// Define props
const props = defineProps({
  slide: {
    type: Object,
    required: true,
  },
  loading: {
    type: Boolean,
    required: true,
  },
});

const showOffers = ref(false);
const sliders = ref([]);
const localLoading = ref(props.loading);
const { t, locale } = useI18n();
const router = useRouter();
const adult_no = ref(1);
const children_no = ref(0);
const checkin = ref('');
const checkout = ref('');
const room_no = ref(1);

// Watch for changes in sliders prop to handle loading and data population
watch(
  () => props.slide,
  (newVal) => {
      sliders.value = newVal.sliders.map((offer) => ({
        title: offer.child?.ar_name ?? '',
        en_title: offer.child?.en_name ?? '',
        imageSrc: offer.child?.image_path ?? '',
        hotelsCount: offer.child?.hotelsCount ?? 0,
        governorate_id: offer.child?.city.governorate_id ?? 0, // Ensure governorate_id is included
      }));
      showOffers.value = sliders.value.length > 0;
      localLoading.value = false; // Ensure local loading is set to false after data is ready
  },
  { immediate: true }
);

const hotelClick = (item) => {
  router.push({
    name: 'Hotels',
    query: {
      governorate: item.governorate_id,
      checkin: checkin.value,
      checkout: checkout.value,
      room_no: room_no.value,
      adult_no: adult_no.value,
      children_no: children_no.value,
    },
  });
};
</script>

<style scoped>
.container {
  margin: auto;
  margin-bottom: 5rem;
  font-family: "Cairo", sans-serif;
  width: 70%;
}
.typing {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}
.city-text {
  color: #ffb325;
  text-decoration: underline;
}
.image-text {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 10px;
  border-radius: 10px;
  margin: 4px;
  font-size: 16px;
}
.typing-header {
  font-size: 26px;
  color: #ffb325;
  font-weight: bold;
}
.typing-paragraph {
  font-size: 14px;
  color: #000;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 600px;
  width: 100%;
}
.grid-container {
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  grid-template-columns: repeat(8, 1fr);
  gap: 10px;
  height: 100%;
}
.item-0 {
  grid-row: 1 / span 4;
  grid-column: 5 / span 2;
}
.item-1 {
  grid-row: 1 / span 2;
  grid-column: 2 / span 3;
}
.item-2 {
  grid-row: 3 / span 3;
  grid-column: 1 / span 2;
}
.item-3 {
  grid-row: 2 / span 3;
  grid-column: 7 / span 2;
}
.item-4 {
  grid-row: 3 / span 4;
  grid-column: 3 / span 2;
}
.item-5 {
  grid-row: 5 / span 2;
  grid-column: 5 / span 3;
}
.grid-item {
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.grid-item img {
  width: 100%;
  border-radius: 10px;
  height: 100%;
  object-fit: cover;
  display: block;
  border: 0.1px rgb(216, 216, 255) solid;
}
.grid-item img:hover {
  cursor: pointer;
}
.overlay-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
}

/* Skeleton Loader Styles */
.skeleton-grid-item {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.skeleton-grid-item:nth-child(1) {
  grid-row: 1 / span 4;
  grid-column: 5 / span 2;
}
.skeleton-grid-item:nth-child(2) {
  grid-row: 1 / span 2;
  grid-column: 2 / span 3;
}
.skeleton-grid-item:nth-child(3) {
  grid-row: 3 / span 3;
  grid-column: 1 / span 2;
}
.skeleton-grid-item:nth-child(4) {
  grid-row: 2 / span 3;
  grid-column: 7 / span 2;
}
.skeleton-grid-item:nth-child(5) {
  grid-row: 3 / span 4;
  grid-column: 3 / span 2;
}
.skeleton-grid-item:nth-child(6) {
  grid-row: 5 / span 2;
  grid-column: 5 / span 3;
}
.skeleton-grid-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  border: 0.1px rgb(216, 216, 255) solid;
}
.skeleton-card-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
}
.skeleton-text {
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 4px;
}
@keyframes pulse {
  0% {
    background-color: #f0f0f0;
  }
  50% {
    background-color: #e0e0e0;
  }
  100% {
    background-color: #f0f0f0;
  }
}
</style>
