<template>
  <Header />
  <HamburgerMenu />
  <RoomDescription />
  <!--<HotelUpperSectionShow />
  <HotelDescription />
  <HotelRoomsList />
  <HotelRating />
  <HotelListOfQuestions />
  <HotelThingsProvided /> -->
  <Footer />
  <CopyRightComponent />
</template>

<script>
import Header from "../components/Header.vue";
import HamburgerMenu from "../components/HamburgerMenu.vue";
import RoomDescription from "../components/BookHotelRoom/RoomDescription.vue";

// import HotelHeader from "../components/ShowHotelComponents/HotelHeader.vue";
// import HotelUpperSectionShow from "../components/ShowHotelComponents/HotelUpperSectionShow.vue";
// import HotelDescription from "../components/ShowHotelComponents/HotelDescription.vue";
// import HotelRoomsList from "../components/ShowHotelComponents/HotelRoomsList.vue";
// import HeaderTyping from "../components/HotelListComponents/HeaderTyping.vue";
// import SearchBox from "../components/SearchBox.vue";
// import HotelListing from "../components/HotelListComponents/HotelListing.vue";
// import HotelRating from "../components/ShowHotelComponents/HotelRating.vue";
// import HotelListOfQuestions from "../components/ShowHotelComponents/HotelListOfQuestions.vue";
// import HotelThingsProvided from "../components/ShowHotelComponents/HotelThingsProvided.vue";
import Footer from "../components/Footer.vue";
import CopyRightComponent from "../components/CopyRightComponent.vue";

export default {
  mounted() {
    window.scrollTo(0, 0);
  },
  name: "MainPage",
  /* eslint-disable */
  components: {
    Header,
    HamburgerMenu,
    RoomDescription,
    Footer,
    CopyRightComponent,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}
</style>
