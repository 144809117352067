<template>
  <div v-if="showOffers || loading" :class="[{ rtl: locale === 'ar' || locale === 'kr' }, 'container']">
    <div
      class="container-header"
      :style="locale === 'ar' || locale === 'kr' ? '' : 'flex-direction: row-reverse;'"
    >
      <div class="typing" :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'">
        <span class="typing-header">{{ slide[`${locale}_title`] }}</span>
        <span class="typing-paragraph">{{ slide[`${locale}_description`] }}</span>
      </div>
      <div class="slider-buttons">
        <div class="buttons-container" @click="moveCards('right')">
          <i class="fa-solid fa-arrow-right-long"></i>
        </div>
        <div class="buttons-container" @click="moveCards('left')">
          <i class="fa-solid fa-arrow-left-long"></i>
        </div>
      </div>
    </div>
    <div
      class="content"
      ref="cardContainer"
      :style="locale === 'ar' || locale === 'kr' ? '' : 'flex-direction: row-reverse;'"
    >
      <template v-if="loading">
        <div v-for="n in 4" :key="n" class="skeleton-card-container">
          <div class="skeleton-image-container"></div>
          <div class="skeleton-card-text">
            <div class="skeleton-text" style="width: 60%"></div>
            <div class="skeleton-text" style="width: 80%"></div>
            <div class="skeleton-text" style="width: 40%"></div>
          </div>
        </div>
      </template>
      <template v-else>
        <div
          v-for="(offer, index) in offers"
          :key="index"
          class="card-container shadow hover:bg-gray-100"
          :style="locale === 'ar' || locale === 'kr' ? '' : 'flex-direction: row-reverse;'"
          @click="hotelClick(offer)"
        >
          <div class="image-container">
            <img :src="offer.image_path" :alt="offer.alt" />
          </div>
          <div class="card-text">
            <h1 :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'" style="font-size: 14px">
              {{ locale === "ar" ? offer.ar_title : offer.en_title }}
            </h1>
            <h1 :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'" style="font-size: 10px">
              {{
                locale === "ar"
                  ? offer.ar_description.slice(0, 255)
                  : offer.en_description.slice(0, 255)
              }}...
            </h1>
            <span :dir="locale === 'ar' || locale === 'kr' ? 'rtl' : 'ltr'" style="margin-bottom: 1rem">
              {{ t("message.endsAt") }}:
              <span class="time-text">{{ offer.end_date }}</span>
            </span>
          </div>
        </div>
      </template>
    </div>
    <div class="show-more-button">
      <router-link
        active-class="active"
        class="hover:text-red duration-500"
        :to="isRTL ? '/offers' : '/offers'"
      >
        <button
          type="button"
          class="text-white bg-[#003b7d] hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-6 py-3 text-center"
        >
          {{ t("message.showMore") }}
        </button>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, watch } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const props = defineProps({
  slide: {
    type: Object,
    required: true,
  },
  loading: {
    type: Boolean,
    required: true,
  },
});

const store = useStore();
const { t, locale } = useI18n();
const router = useRouter();
const isRTL = ref(locale.value === "ar");

const cardContainer = ref(null);
const showOffers = ref(false);
const offers = ref(props.slide);
const adult_no = ref(1);
const children_no = ref(0);
const checkin = ref("");
const checkout = ref("");
const room_no = ref(1);

const hotelClick = (item) => {
  router.push({
    name: "Hotels",
    query: {
      governorate: item.governorate_id,
      checkin: checkin.value,
      checkout: checkout.value,
      room_no: room_no.value,
      adult_no: adult_no.value,
      children_no: children_no.value,
    },
  });
};


watch(
  () => store.getters.offers.data,
  (newData) => {
    offers.value = newData;
    showOffers.value = !!newData.length;
  }
);

const moveCards = (direction) => {
  const container = cardContainer.value;
  const cardWidth = container.offsetWidth;

  if (direction === "left") {
    container.scrollTo({
      left: container.scrollLeft - cardWidth,
      behavior: "smooth",
    });
  } else if (direction === "right") {
    container.scrollTo({
      left: container.scrollLeft + cardWidth,
      behavior: "smooth",
    });
  }
};
</script>

<style scoped>
.container {
  margin: auto;
  font-family: "Cairo", sans-serif;
  width: 70%;
  direction: rtl;
  margin-bottom: 3rem;
}
.content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 20px;
  overflow: hidden;
  padding: 1rem 0rem;
}
.card-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 185px;
  width: 420px;
  border-radius: 10px;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.card-container:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: all 0.4s ease-in-out;
}
.card-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  font-size: 12px;
  margin: 10px;
  width: 245px;
  height: 100%;
}
.image-container {
  height: 185px;
  width: 160px;
}
.image-container img {
  width: 100%;
  border-radius: 10px;
  height: 100%;
  object-fit: cover;
  display: block;
}
.typing {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.typing-header {
  font-size: 26px;
  color: #ffb325;
  font-weight: bold;
}
.typing-paragraph {
  font-size: 14px;
  color: #000;
}
.time-text {
  color: #ffb325;
}
.show-more-button {
  margin-top: 30px;
}
.show-more-button button {
  background-color: #003b7d;
}
.show-more-button button:hover {
  background-color: #043961;
  transition: all 0.5s ease-in-out;
}
.container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.slider-buttons {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  gap: 10px;
}
.buttons-container {
  background-color: #003b7d;
  color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.buttons-container:hover {
  background-color: #043961;
  transform: scale(1.05);
  transition: all 0.4s ease-in-out;
}
/* Skeleton Loader Styles */
.skeleton-card-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 185px;
  width: 420px;
  border-radius: 10px;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  background-color: #fff; 
  /* animation: pulse 1.5s infinite; */
}
.skeleton-image-container {
  height: 185px;
  width: 160px;
  background-color: #e0e0e0; /* Lighter image container color */
  border-radius: 10px;
}
.skeleton-card-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  font-size: 12px;
  margin: 10px;
  width: 245px;
  height: 100%;
}
.skeleton-text {
  height: 20px;
  background-color: #e0e0e0; /* Lighter text color */
  border-radius: 4px;
}
@keyframes pulse {
  0% {
    background-color: #f0f0f0;
  }
  50% {
    background-color: #e0e0e0;
  }
  100% {
    background-color: #f0f0f0;
  }
}

</style>
