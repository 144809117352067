<template>
  <div class="footer-container">
    <div class="footer-content">
      {{ $t("message.footerText") }}
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "FooterComp",
  setup() {
    const { t } = useI18n();
    return { t };
  },
};
</script>

<style scoped>
.footer-container {
  font-family: "Cairo", sans-serif;
  background: #fff;
  direction: rtl;
  padding: 15px 20px;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  text-align: center;
  font-size: 14px;
}

@media (min-width: 576px) {
  .footer-content {
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .footer-content {
    font-size: 18px;
  }
}

@media (min-width: 992px) {
  .footer-content {
    font-size: 18px;
  }
}
</style>
